import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import { toast } from "react-toastify";
import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { toDate } from "date-fns-tz";

import api from "../../services/api";
import logo from "../../assets/logo.png";

const downloadInvoice = () => {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [816, 1056], //816, 1056
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice-001.pdf");
  });
};

const createInvoice = async (info, items) => {
  info.items = items;

  try {
    if (info.id) {
      await api.put(`bills/${info.id}`, info);
      window.location.href = "/bills";
      toast.success("Success to create invoice!");
    } else {
      await api.post("bills", info);
      // navigate("/membermanagement",{replace: true});
      window.location.href = "/bills";
      toast.success("Success to create invoice!");
    }
  } catch (err) {
    const errMsg = err.response
      ? err.response.data.error
      : "Something went wrong!";
    toast.error(errMsg);
  }
};

const InvoiceModal = ({ showModal, closeModal, info, items, user }) => {
  return (
    <div>
      <Modal show={showModal} onHide={closeModal} size="lg" centered>
        <div className="py-4 px-5">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
                className="d-block w-100"
                onClick={() => createInvoice(info, items)}
              >
                <BiPaperPlane
                  style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                  className="me-2"
                />
                Send Invoice
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant="outline-primary"
                className="d-block w-100 mt-3 mt-md-0"
                onClick={downloadInvoice}
              >
                <BiCloudDownload
                  style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                  className="me-2"
                />
                Download Copy
              </Button>
            </Col>
          </Row>
        </div>

        <div id="invoiceCapture" className="px-5">
          <div style={{ minHeight: "1056px", position: "relative" }}>
            <div className="p-4">
              <Row className="py-1">
                <Col md={8}></Col>
                <Col md={4}>
                  {" "}
                  <img
                    src={logo}
                    alt="MARAM Fitwork"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={8}>
                  <div style={{ fontSize: "12px", marginTop: "10px" }}>
                    Maram Fitwork SL • C/ÁLVARO RAMOS 33B • 38350 TACORONTE
                  </div>
                </Col>
                <Col md={4}>
                  <h6 className="fw-bold mt-1 mb-2">Maram Fitwork SL</h6>
                  <div className="mb-1">C/ÁLVARO RAMOS 33B</div>
                  <div className="mb-1">38350 TACORONTE</div>
                  <div className="fw-bold mb-1">CIF B76750546</div>
                </Col>
              </Row>
              <Row className="py-2">
                <Col md={8}>
                  <div className="">
                    <div>{info.billTo || ""}</div>
                    <div>{info.billToAddress || ""}</div>
                    <div>{info.billToEmail || ""}</div>
                  </div>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Row className="py-2">
                <Col md={8}></Col>
                <Col md={4} className="text-end">
                  <div className="mb-1">
                    TACORONTE, {format(new Date(), "dd.MM.yyyy")}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="p-4">
              <Row className="mb-4">
                <Col md={4} className="">
                  <div className="fw-bold mb-2">Invoice Number</div>
                  <div>{info.invoiceNumber || ""}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mb-2">DIN Number</div>
                  <div>{user.dinNumber || ""}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mb-2">Customer Number</div>
                  <div>{user.customerNumber}</div>
                </Col>
              </Row>
              <Table className="mb-0 mr-1">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th className="text-end">Price</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => {
                    return (
                      <tr id={i} key={i}>
                        <td>{item.name}</td>
                        <td className="text-end" style={{ width: "100px" }}>
                          {info.currency} {item.price}
                        </td>
                        <td className="text-end" style={{ width: "100px" }}>
                          {info.currency} {item.price * item.qty}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      Sub Total
                    </td>
                    <td className="text-end" style={{ width: "100px" }}>
                      {info.currency} {info.subTotal}
                    </td>
                  </tr>
                  {info.taxAmmount != 0.0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        Tax({info.taxStatus})
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {info.currency} {info.taxAmmount}
                      </td>
                    </tr>
                  )}
                  {info.discountAmmount != 0.0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        Discount
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {info.currency} {info.discountAmmount}
                      </td>
                    </tr>
                  )}
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      Total
                    </td>
                    <td className="text-end" style={{ width: "100px" }}>
                      {info.currency} {info.total}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {info.notes && (
                <div className="bg-light py-3 px-4 rounded">{info.notes}</div>
              )}
            </div>

            <div style={{ width: "100%", position: "absolute", bottom: 10 }}>
              <div className="d-flex flex-column justify-content-center align-items-center  w-100 p-4">
                <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                  Maram Fitwork SL • C/ÁLVARO RAMOS 33B • 38350 TACORONTE • 922
                  896 679 • https://fitwork.es
                </div>
                <div style={{ fontSize: "8px", marginTop: "2px" }}>
                  CAJAMAR CAJA RURAL, S.C.C. • IBAN ES8030581318062720006426 •
                  BIC CCRIES2AXXX • NÚMERO CIF B76750546
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <hr className="mt-4 mb-3" />
    </div>
  );
};

InvoiceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  items: PropTypes.array,
  info: PropTypes.object,
};

export default InvoiceModal;
