import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { MdCheckCircle, MdOutlineRemoveRedEye } from "react-icons/md";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { parseISO, format } from "date-fns";
import { formatedFinacial } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

const BillListModal = ({ showModal, closeModal, bills }) => {
  const navigate = useNavigate();
  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        "&:nth-child(1)": {
          justifyContent: "center",
        },
        "&:nth-child(2)": {
          justifyContent: "center",
        },
        "&:nth-child(3)": {
          justifyContent: "right",
        },
        "&:nth-child(4)": {
          justifyContent: "right",
        },
        "&:nth-child(5)": {
          justifyContent: "right",
        },
        "&:nth-child(6)": {
          justifyContent: "right",
        },
        "&:nth-child(7)": {
          justifyContent: "center",
        },
        "&:nth-child(8)": {
          justifyContent: "center",
        },
        "&:nth-child(9)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };
  const columns = [
    {
      
      name: "Number",
      selector: (row) => row.invoiceNumber,
      style: { justifyContent: "center" },
    },
    {
      name: "Date",
      selector: (row) => format(parseISO(row.currentDate), "dd.MM.yyyy"),
      style: { justifyContent: "center" },
    },

    {
      name: "SubTotal",
      selector: (row) => formatedFinacial(row.subTotal) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Tax",
      selector: (row) => formatedFinacial(row.taxAmmount) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Discount",
      selector: (row) => formatedFinacial(row.discountAmmount) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Total",
      selector: (row) => formatedFinacial(row.total) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "PaidDate",
      selector: (row) =>
        row.markDate ? format(parseISO(row.markDate), "dd.MM.yyyy") : "",
      style: { justifyContent: "center" },
    },
    {
      name: "Status",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) =>
        row.status ? (
          <MdCheckCircle size={20} color="#42cb59" />
        ) : (
          <MdCheckCircle size={20} color="#dddddd" />
        ),
      ignoreRowClick: true,
    },

    {
      name: "Action",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) => <button style={{background: "transparent", border: "none"}} onClick={() => handleEditmBill(row)}><MdOutlineRemoveRedEye size={20} color="#444444"/></button>,
      ignoreRowClick: true,
    },
  ];
  const handleEditmBill = async(info) => {
    try {
      const response = await api.get(`bills/bill-items/${info.id}`);
    info.billItems = response.data.billItems;  
    info.type = 'edit';
    
    navigate('/invoiceform', {state:info});
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error.message
        : "Something went wrong!";
      toast.error(errMsg);
    }
    
  }

  return (
    <div>
      <Modal show={showModal} onHide={closeModal} size="xl" centered>
        <div id="invoiceCapture">
          <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-3">
            <div className="w-100">
              <h6 className="fw-bold my-2">Invoice List</h6>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <DataTable
                columns={columns}
                data={bills}
                customStyles={customStyles}
              />
            </Row>
          </div>
        </div>
       
      </Modal>
    </div>
  );
};

BillListModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  bills: PropTypes.array,
};

export default BillListModal;
