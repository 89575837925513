import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./config/ReactotronConfig";

import RoutesWapper from "./routes";

import { store, persistor } from "./store";

import GlobalStyle from "./styles/global";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
          <RoutesWapper />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
