import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import Input from "../../components/Form/Input";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { signInRequest } from "../../store/modules/auth/actions";

import logo from "../../assets/logo.png";

import { Content } from "./styles";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("* Email required"),
  password: Yup.string().required("* Password required"),
});

const SignIn = () => {
  const location = useLocation();
  const state = location.state;
  const isMobile = state && state.from == "mobile" ? true : false;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password, isMobile));
  }
  return (
    <Content>
      <img src={logo} alt="MARAM Fitwork" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <strong>EMAIL</strong>
        <Input name="email" type="email" placeholder="example@email.com" />
        <strong>PASSWORD</strong>
        <Input name="password" type="password" placeholder="*************" />
        <button type="submit">{loading ? "Loading..." : "Login"}</button>
      </Form>
    </Content>
  );
};

export default SignIn;
