import React, { useState, useEffect, useCallback } from "react";
import { MdCheck, MdKeyboardArrowLeft, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import {
  addDays,
  addMonths,
  differenceInDays,
  differenceInMonths,
  format,
  parseISO,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns";
import PropTypes from "prop-types";
import { toDate } from "date-fns-tz";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../services/api";
import Input from "../../components/Form/Input";

import {
  Container,
  Head,
  DivForm,
  Selec,
  ASelect,
  DPicker,
  Form,
  Footer,
  LoadingWraper,
} from "./styles";

const MemberManagementForm = ({ closeModal, memberManagement, from }) => {
  const [memberships, setMemberships] = useState([]);

  const [selectedMember, setSelectedMember] = useState(
    memberManagement && memberManagement.user ? memberManagement.user : ""
  );

  const [selectedMembership, setSelectedMembership] = useState(
    memberManagement && memberManagement.membership
      ? memberManagement.membership
      : null
  );
  const [createDate, setCreateDate] = useState(
    memberManagement && memberManagement.startDate
      ? memberManagement.startDate
      : null
  );
  const [latestInvoiceDate, setLatestInvoiceDate] = useState(
    memberManagement && memberManagement.endDate
      ? memberManagement.endDate
      : null
  );

  const [totalDuration, setTotalDuration] = useState(
    selectedMembership
      ? selectedMembership.durationType
        ? differenceInDays(latestInvoiceDate, createDate)
        : differenceInMonths(latestInvoiceDate, createDate)
      : 0
  );
  const [totalPrice, setTotalPrice] = useState(
    memberManagement && memberManagement.price ? memberManagement.price : 0
  );

  const [newStartDate, setNewStartDate] = useState(latestInvoiceDate);
  const [nextInvoiceDate, setNextInvoiceDate] = useState(null);
  const [newDuration, setNewDuration] = useState(0);
  const [price, setPrice] = useState(0);

  const [step, setStep] = useState(1);

  const [durationTitle, setDurationTitle] = useState("Duration(month)");

  const [isCancelMembership, setIsCancelMembership] = useState(
    memberManagement && memberManagement.cancelDate ? true : false
  );
  const [cancelDate, setCancelDate] = useState(
    memberManagement && memberManagement.cancelDate
      ? memberManagement.cancelDate
      : null
  );

  const [remainNumberOfUsage, setRemainNumberOfUsage] = useState(
    memberManagement && memberManagement.remainNumberOfUsage
      ? memberManagement.remainNumberOfUsage
      : null
  );
  const [isSaveLoading, setIsSaveLoading] = React.useState(false);
  /**
   * Get all members and memberships from api
   */
  useEffect(() => {
    async function loadMembersAndMemberships() {
      const membershipRes = await api.get("memberships");
      // const activeMembership = membershipRes.data.memberships.filter(
      //   (item) => item.isActive == 1
      // );
      setMemberships(membershipRes.data.memberships);
    }
    loadMembersAndMemberships();
  }, []);

  useEffect(() => {
    if (selectedMembership) {
      setDurationTitle(
        `Duration(${selectedMembership.durationType ? "day" : "month"})`
      );
      setNewDuration(selectedMembership.duration);
      setStep(selectedMembership.duration);

      if (newStartDate) {
        const tmpDate =
          selectedMembership.durationType == 0
            ? addMonths(
                toDate(newStartDate),
                parseInt(selectedMembership.duration)
              )
            : addDays(
                toDate(newStartDate),
                parseInt(selectedMembership.duration)
              );
        setNextInvoiceDate(format(tmpDate, "yyyy-MM-dd"));
      }

      setPrice(selectedMembership.totalPrice);
      if (selectedMembership && selectedMembership.isRecurring == 0) {
        setRemainNumberOfUsage(selectedMembership.numberOfUsage);
      }
    }
  }, [selectedMembership]);

  useEffect(() => {
    if (selectedMembership) {
      if (newStartDate) {
        const tmpDate =
          selectedMembership.durationType == 0
            ? addMonths(toDate(newStartDate), parseInt(newDuration))
            : addDays(toDate(newStartDate), parseInt(newDuration));
        setNextInvoiceDate(format(tmpDate, "yyyy-MM-dd"));
      }

      setPrice(
        parseFloat(
          selectedMembership.totalPrice * (newDuration / step)
        ).toFixed(2)
      );
    }
  }, [newStartDate, newDuration]);

  /**
   * Save button to insert data into database
   */
  const handleSave = async () => {
    setIsSaveLoading(true);
    if (
      cancelDate &&
      (isAfter(parseISO(cancelDate), parseISO(newStartDate)) ||
        isEqual(parseISO(cancelDate), parseISO(newStartDate))) &&
      isBefore(parseISO(cancelDate), parseISO(nextInvoiceDate))
    ) {
      toast.error(
        "The cancel membership date exist between startdate and next invoice date!"
      );
      setIsSaveLoading(false);
      return;
    }
    if (memberManagement && memberManagement.id) {
      if (isCancelMembership && cancelDate) {
        const res = await api.put(
          `enrollments/${memberManagement.id}/cancel-date`,
          {
            id: memberManagement.id,
            cancelDate: cancelDate,
          }
        );
        setIsSaveLoading(false);
        toast.success("Success to cancel membership!");
        window.location.href = "/membermanagement";
      } else {
        try {
          await api.put(`enrollments/${memberManagement.id}`, {
            userId: selectedMember.id,
            membershipId: selectedMembership.id,
            remainNumberOfUsage: remainNumberOfUsage,
            startDate: newStartDate ? newStartDate : null,
            endDate: nextInvoiceDate ? nextInvoiceDate : null,
            cancelDate: null,
          });

          toast.success("Membership edited!");
          if (from == "modal") {
            closeModal();
          } else {
            window.location.href = "/membermanagement";
          }
        } catch (err) {
          toast.error("Something went wrong!");
        } finally {
          setIsSaveLoading(false);
        }
      }
    } else {
      try {
        await api.post("enrollments", {
          userId: selectedMember.id,
          membershipId: selectedMembership.id,
          remainNumberOfUsage: remainNumberOfUsage,
          startDate: newStartDate ? newStartDate : null,
          endDate: nextInvoiceDate ? nextInvoiceDate : null,
          // cancelDate: cancelDate ? cancelDate : null,
        });
        toast.success("Membership added to member!");
        if (from == "modal") {
          closeModal();
        } else {
          window.location.href = "/membermanagement";
        }
      } catch (err) {
        const errMsg = err.response
          ? err.response.data.error
          : "Something went wrong!";
        toast.error(errMsg);
      } finally {
        setIsSaveLoading(false);
      }
    }
  };

  /**
   * Get back to membermanagement page
   */
  const handleGoBack = () => {
    closeModal();
  };

  /**
   * Update endDate input when new date is passed
   */
  const handleDateChange = useCallback(
    (date) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      setNewStartDate(date ? formattedDate : null);
    },
    [newDuration]
  );

  const handleCancelDateChange = useCallback((date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setCancelDate(date ? formattedDate : null);
  }, []);

  const handleMembershipChange = (item) => {
    setSelectedMembership({
      id: item.id,
      title: item.title,
      duration: item.duration,
      durationType: item.durationType,
      totalPrice: item.totalPrice,
      isRecurring: item.isRecurring,
      numberOfUsage: item.numberOfUsage,
    });
  };

  /**
   * Update sectedMember state with new member input
   */
  const handleMemberChange = (event) => {
    setSelectedMember({ id: event.value, name: event.label });
  };

  /**
   * Load members into async select
   */

  const handleLoadMembers = async (inputValue) => {
    const response = await api.get(`users/search/user-list`, {
      params: {
        key: inputValue.toString().toLowerCase(),
      },
    });
    const inputMembers = response.data.users.map((member) => ({
      value: member.id,
      label: member.firstName + " " + member.lastName,
    }));
    return inputMembers;
  };

  const handleMemberValue = () => {
    if (selectedMember) {
      return {
        value: selectedMember.id,
        label: selectedMember.firstName + " " + selectedMember.lastName,
      };
    }
  };

  const handleMembershipValue = () => {
    if (selectedMembership) {
      return {
        value: selectedMembership.id,
        label: selectedMembership.title,
      };
    }
  };

  const onChangDuration = (duration) => {
    if (duration) {
      setNewDuration(parseInt(duration / step) * step);
    }
  };

  const isDisabled = memberManagement && memberManagement.id ? true : false;

  return (
    <Container>
      <Form /* schema={} */>
        <Head>
          <h1>Member Management Form</h1>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdClose size={20} className="mdAdd" />
            </button>
          </div>
        </Head>
        <DivForm>
          <div>
            <ul>
              <li>
                Member
                <ASelect
                  id="names"
                  cacheOptions
                  loadOptions={handleLoadMembers}
                  defaultOptions
                  defaultValue={handleMemberValue()}
                  onChange={handleMemberChange}
                  isDisabled={isDisabled}
                />
              </li>
              <li>
                Membership
                <Selec
                  id="titles"
                  options={memberships.map((membership) => ({
                    value: membership.id,
                    label: membership.title,
                  }))}
                  defaultValue={handleMembershipValue()}
                  onChange={(e) => {
                    const item = memberships.filter(
                      (item) => item.id == e.value
                    );
                    handleMembershipChange(item[0]);
                  }}
                  isDisabled={isDisabled}
                />
              </li>
              {selectedMembership && selectedMembership.isRecurring == 0 && (
                <li>
                  NumberOfUsage
                  <Input
                    name="remainNumberOfUsage"
                    type="text"
                    value={remainNumberOfUsage ? remainNumberOfUsage : ""}
                    disabled
                  />
                </li>
              )}
            </ul>
          </div>
          {memberManagement && memberManagement.id && (
            <div>
              <ul>
                <li>
                  {memberManagement && memberManagement.id
                    ? "Start Date"
                    : "Create Date"}
                  <DPicker
                    className="dp"
                    dateFormat="dd.MM.yyyy"
                    selected={createDate ? toDate(createDate) : null}
                    onChange={handleDateChange}
                    value={createDate ? toDate(createDate) : null}
                    disabled
                  />
                </li>

                <li>
                  Total {durationTitle}
                  <Input
                    name="duration"
                    type="number"
                    value={totalDuration}
                    disabled
                  />
                </li>
                <li>
                  Total Price
                  <Input
                    name="totalPrice"
                    type="text"
                    value={totalPrice}
                    disabled
                  />
                </li>
              </ul>
            </div>
          )}
          <div>
            <ul>
              <li>
                {memberManagement && memberManagement.id
                  ? "Next Invoice Date"
                  : "Start Date"}
                <DPicker
                  className="dp"
                  dateFormat="dd.MM.yyyy"
                  selected={newStartDate ? toDate(newStartDate) : null}
                  onChange={handleDateChange}
                  value={newStartDate ? toDate(newStartDate) : null}
                  disabled={isDisabled}
                />
              </li>
              <li>
                {durationTitle}
                <Input
                  name="duration"
                  type="number"
                  min={0}
                  step={step}
                  value={newDuration}
                  onChange={(e) => onChangDuration(e.target.value)}
                  disabled
                />
              </li>
              <li>
                Price
                <Input name="price" type="text" value={price} disabled />
              </li>
              {!(memberManagement && memberManagement.id) && (
                <li>
                  Next Invoice Date
                  <DPicker
                    name="endDate"
                    className="dp"
                    dateFormat="dd.MM.yyyy"
                    selected={nextInvoiceDate ? toDate(nextInvoiceDate) : null}
                    value={nextInvoiceDate ? toDate(nextInvoiceDate) : null}
                    disabled
                  />
                </li>
              )}
            </ul>
          </div>
          {memberManagement && memberManagement.id && (
            <div>
              <label className="cancelMembershipTitle">Cancel Membership</label>
              <Input
                name="totalPrice"
                type="checkbox"
                checked={isCancelMembership}
                onChange={(e) => {
                  setIsCancelMembership(e.target.checked);
                  setCancelDate(null);
                }}
              />

              {isCancelMembership && (
                <DPicker
                  className="cancelMembershipPicker"
                  dateFormat="dd.MM.yyyy"
                  placeholderText="dd.MM.yyyy"
                  selected={cancelDate ? toDate(cancelDate) : null}
                  onChange={handleCancelDateChange}
                  value={cancelDate ? toDate(cancelDate) : null}
                />
              )}
            </div>
          )}
        </DivForm>
        <Footer>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdKeyboardArrowLeft size={20} className="mdAdd" />
              Back
            </button>
            <button
              className="defaultBtn generateInvoiceBtn"
              type="button"
              onClick={handleSave}
              disabled={isSaveLoading}
            >
              <MdCheck size={20} className="mdAdd" />
              {isCancelMembership
                ? "Cancel Membership"
                : "Generate Next Invoice"}
            </button>
          </div>
        </Footer>
      </Form>
      {isSaveLoading && (
        <LoadingWraper>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#666666"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </LoadingWraper>
      )}
    </Container>
  );
};

MemberManagementForm.propTypes = {
  closeModal: PropTypes.func,
  members: PropTypes.object,
};

export default MemberManagementForm;
