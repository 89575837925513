import React, { useEffect } from "react";
import { useNavigate, useOutlet, useLocation } from "react-router-dom";
import { store } from "../../store";
import { NormalWrapper } from "./styles";
const HomeLayout = () => {
  const { signed } = store.getState().auth;
  const outlet = useOutlet();
  useEffect(() => {
    if (signed) {
      window.location.href = "/members";
    }
  }, []);
  return !signed ? <NormalWrapper>{outlet}</NormalWrapper> : <></>;
};

export default HomeLayout;
