import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { signOut } from "../../store/modules/auth/actions";
import api from "../../services/api";
import logo from "../../assets/logo.png";
// import logoP2 from '../../assets/logoP2.png';

import { Container, Content, Profile, Navigation } from "./styles";

export default function Header() {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }
  const user = useSelector((state) => state.auth);
  const location = useLocation();

  const openDoor = async (isInDoor) => {
    try {
      const response = await api.post("/open-close-door", {
        isInDoor: isInDoor,
      });
      if (response.data.status) {
        toast.success(`${isInDoor ? "In" : "Out"} Door open successfully!`);
      } else {
        toast.error(`${isInDoor ? "In" : "Out"} door open failed!`);
      }
    } catch (error) {
      const errMsg = error.response
        ? error.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    }
  };

  return (
    <Container>
      <Content>
        <Navigation>
          <div>
            <a href="/members">
              <img src={logo} alt="MARAM Fitwork" />
            </a>
          </div>
          <nav>
            <NavLink
              to="members"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              {" "}
              MEMBERS
            </NavLink>
            <NavLink
              to="memberships"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              MEMBERSHIPS
            </NavLink>
            <NavLink
              to="membermanagement"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              MEMBER MANAGEMENT
            </NavLink>
            <NavLink
              to="bills"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              BILLS
            </NavLink>
            <NavLink
              to="payments"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              PAYMENTS
            </NavLink>
            <NavLink
              to="history"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              ENTRANCE HISTORY
            </NavLink>
            {/* <NavLink
              to="questions"
              className={({ isActive }) =>
                isActive ? "active nav-link" : "nav-link"
              }
            >
              QUESTIONS
            </NavLink> */}
            <NavLink
              to="settings"
              className={
                location.pathname.includes("settings")
                  ? "active nav-link"
                  : "nav-link"
              }
            >
              SETTINGS
            </NavLink>
          </nav>
        </Navigation>
        <aside>
          <Profile>
            <div className="openDoorBtn" onClick={() => openDoor(1)}>
              Open door(in)
            </div>
            <div className="openDoorBtn" onClick={() => openDoor(0)}>
              Open door(out)
            </div>
            <div>
              <strong>{user.user.firstName}</strong>
              <Link to="/" onClick={handleSignOut}>
                Log out
              </Link>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
