import axios from "axios";
export const baseURL = "https://fitwork.webconnect.pro/api";
// export const baseURL = "http://localhost:3334/api";
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
