import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
  },
};
Modal.setAppElement("#root");

export default function CustomModal({ isOpen, children }) {
  return (
    <Modal
        isOpen={isOpen}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add&Edit Modal"
      >
        {children}
    </Modal>
  );
}

CustomModal.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired
};
