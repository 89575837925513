import { persistStore,FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import { configureStore } from "@reduxjs/toolkit";

// const sagaMonitor =
//   process.env.NODE_ENV === 'development'
//     ? console.tron.createSagaMonitor()
//     : null;
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({ reducer: persistReducers(rootReducer), middleware: (getDefaultMiddleware) =>getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(sagaMiddleware) });

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
