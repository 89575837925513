import styled from 'styled-components';

export const Container = styled.div`
padding: 0 25px;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

  .active {
    color: #444444;
  }
  strong {
    font-size: 16px;
  }

  span {
    font-size: 16px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 15px 0;
  width: 90%;
 
  
`;

export const ContentWrapper = styled.div`
  
  margin-bottom:30px;
  width: 90%;
  display: flex;
  justify-content: space-between;

  .side-bar-menu {
    width: 15%;
    height: 75vh;
    padding: 20px;
    background: white;
    border-radius: 4px;
    
  }

  .main-content {
    width: 85%;
    margin-left: 10px;
    padding: 20px;
    background: white;
    border-radius: 4px;
  }
  

`;


export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  nav {
    display: flex;
    flex-direction: column;

    a {
      font-weight: bold;
      padding: 10px;
      font-size: 15px;
      color: #999999;
      text-align: left;
    }
  }
`;
