import styled from "styled-components";
import { Form as Unform } from "@unform/web";
import { darken } from "polished";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";

import Select from "react-select";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.divBtn {
    display: flex;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    color: #fff;
    margin: 0 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }
  .membershipTitle {
    font-size: 16px;
  }
`;
export const Form = styled(Unform)`
  width: 100%;
`;
export const DivForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  border-radius: 6px;
  width: 100%;

  .profileImgWrap {
    width: 300px;
    margin-right: 10px;
  }
  .profileContentWrap {
    width: 70%;
    flex-grow: 1;
  }

  .avatarWrap {
    margin-top: 5px;
    width: 300px;
    height: 400px;
    border: 1px dotted #ccc;
  }

  .avatarWrap img {
    object-fit: cover;
  }

  .fileInput {
    margin-top: 25px;
    width: 100%;
  }

  .underline {
    margin-top: -8px;
    margin-left: 35px;
  }
  .sectionTitle {
    margin-top: 30px;
    padding-left: 2.2rem;
  }

  .btnContainer {
    display: flex;
    justify-content: center;
  }

  .btnContainer button {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }

  input {
    margin: 5px 0;
    padding: 10px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0.1px;
  }

  div {
    ul {
      display: flex;
      justify-content: space-between;
    }

    li {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin-top: -20px;
  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 100px;
    color: #fff;
    margin: 5px 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }
`;

export const DPicker = styled(DatePicker)`
  width: 100% !important;
`;

export const DTimePicker = styled(DateTimePicker)`
  width: 100% !important;
  min-width: 255px;
  margin: 5px 0;
  padding: 5px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 0.1px;

  .react-datetime-picker__wrapper {
    border: none;
  }
  .react-datetime-picker__calendar {
    width: 350px;
    max-width: 100vw;
    padding: 10px;
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
  }
  input {
    margin: 0px;
    padding: 0px;
    border: none;
  }
`;

export const Selec = styled(Select)`
  width: 100% !important;
  padding: 5px 0;
  font-size: 16px;
  height: 41px;
  min-width: 200px;

  .css-1hwfws3 {
    height: 39px;
  }
`;

export const ContentWrapper = styled.div`
  background: white;
  border-radius: 4px;
  // width: 100%;

  .rdt_Table {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
  }

  .rdt_TableRow {
    min-height: 35px;
  }

  button.actionBtn {
    font-size: 15px;
    color: #000;
    width: 60px;
    font-weight: normal;
    background: #ffffff;
    border: 0;
    padding: 5px;
  }

  .membershipLabel {
    color: red;
  }

  .membershipLabel.active {
    color: #097609;
  }
`;

export const LoadingWraper = styled.div`
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
