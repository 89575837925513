import React, { useState, useEffect } from "react";
import { Container, Head, DivForm, Form } from "./styles";
import Input from "../../../components/Form/Input";
import { MdCheck } from "react-icons/md";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Payment = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [paymentInfo, setPaymentInfo] = useState({
    publishKeyTest:'',
    secretKeyTest:'',
    terminalReaderTest:'',
    publishKeyProd: '',
    secretKeyProd: '',
    terminalReaderProd: ''
  });
  useEffect(() => {
    async function loadPaymentSettings() {
      const response = await api.get("settings/info/payment");
      setPaymentInfo(response.data.info);
      setIsLoading(false);
    }
    loadPaymentSettings();
  }, []);

  const handleSave = async() => {
    try{
      const response = await api.post("settings/info/payment", paymentInfo);
      toast.success("Strip information edited successfully!");
    }catch(err){
      toast.error("Something went wrong!");
    }
  };
  return (
    <Container>
      <Form
        /* schema={} */ onSubmit={handleSave}
        encType={"multipart/form-data"}
      >
        <Head>
        <div>Strip Info</div>
        <div >          
            <button className="defaultBtn" type="submit">
              <MdCheck size={20} className="mdAdd" />
              SAVE
            </button>
          </div>
      </Head>
        <hr className="my-3" />
        <DivForm>
          <div className="contentWrap">
            <div className="sandboxTitle"><strong>Sandbox/Test mode</strong></div>
            <div>
              <ul>
                <li>
                  <strong>Publishable Key</strong>
                  <Input
                    name="publishKeyTest"
                    type="text"
                    value={paymentInfo.publishKeyTest}
                    onChange={(e) => {
                        setPaymentInfo({
                        ...paymentInfo,
                        publishKeyTest: e.target.value,
                      });
                    }}
                  />
                </li>
                
                <li>
                  <strong>Secret Key</strong>
                  <Input
                    name="secretKeyTest"
                    type="text"
                    value={paymentInfo.secretKeyTest}
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        secretKeyTest: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Terminal Reader</strong>
                  <Input
                    name="terminalReaderTest"
                    type="text"
                    value={paymentInfo.terminalReaderTest}
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        terminalReaderTest: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>       
          
            <div className="productionTitle"><strong>Production mode</strong></div>
            <div>
              <ul>               
                <li>
                  <strong>Publishable Key</strong>
                  <Input
                    name="publishKeyProd"
                    type="text"
                    value={paymentInfo.publishKeyProd}
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        publishKeyProd: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Secret Key</strong>
                  <Input
                    name="secretKeyProd"
                    type="text"
                    value={paymentInfo.secretKeyProd}
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        secretKeyProd: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Terminal Reader</strong>
                  <Input
                    name="terminalReaderProd"
                    type="text"
                    value={paymentInfo.terminalReaderProd}
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        terminalReaderProd: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </DivForm>      
      </Form>
    </Container>
  );
};

export default Payment;
