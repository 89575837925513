import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../services/api";

import { signInSuccess, signInFailure } from "./actions";

const loginUserData = async (userData) => {
  return api
    .post("/login", userData)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export function* signIn({ payload }) {
  try {
    const { email, password, isMobile } = payload;

    const response = yield call(loginUserData, {
      email,
      password,
      role: "admin",
    });

    api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

    yield put(signInSuccess(response.data.token, response.data.user));

    window.location.href = isMobile ? "/mobile" : "/members";
  } catch (err) {
    const errMsg = err.response.data.error;
    toast.error(errMsg);
    yield put(signInFailure());
  }
}

export function signOut() {
  window.location.href = "/";
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
