import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import api from "../../services/api";

import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import InvoiceModal from "../../components/Invoice/InvoiceModal";
import MarkBillModal from "../../components/Invoice/MarkBillModal";
import InvoiceItem from "../../components/Invoice/InvoiceItem";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { confirmAlert } from "react-confirm-alert";
import "../../components/ConfirmAlert/teste.css";
import { RotatingLines } from "react-loader-spinner";
import "./styles.css";

const InvoiceForm = () => {
  const location = useLocation();
  const state = location.state;
  const type = state.type == "edit";
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMarkModal, setIsOpenMarkModal] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [billInfo, setBillInfo] = useState({
    id: type ? state.id : 0,
    currency: type ? state.currency : "€",
    currentDate: type ? state.currentDate : format(new Date(), "yyyy-MM-dd"),
    invoiceNumber: type ? state.invoiceNumber : 0,
    dateOfIssue: type ? state.dateOfIssue : format(new Date(), "yyyy-MM-dd"),
    toUserId: type ? state.toUserId : 0,
    billTo: type ? state.billTo : "",
    billToEmail: type ? state.billToEmail : "",
    billToAddress: type ? state.billToAddress : "",
    billFrom: type ? state.billFrom : state.companyInfo.companyName,
    billFromEmail: type ? state.billFromEmail : state.companyInfo.email,
    billFromAddress: type
      ? state.billFromAddress
      : state.companyInfo.street +
        ", " +
        state.companyInfo.houseNumber +
        " | " +
        state.companyInfo.city,
    notes: type ? state.notes : "",
    total: type ? state.total : 0.0,
    subTotal: type ? state.subTotal : 0.0,
    taxRate: type ? state.taxRate : state.defaultTaxRate.taxRate,
    taxStatus: type ? state.taxStatus : state.defaultTaxStatus,
    taxAmmount: type ? state.taxAmmount : 0.0,
    discountRate: type ? state.discountRate : 0.0,
    discountAmmount: type ? state.discountAmmount : 0.0,
    status: type ? state.status : 0,
  });

  const [isShowCancelBtn, setIsShowCancelBtn] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [items, setItems] = useState(
    state && state.billItems
      ? state.billItems
      : [
          {
            id: 0,
            name: "",
            price: 1,
            qty: 1,
            startDate: new Date(),
            endDate: new Date(),
          },
        ]
  );

  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(
    type
      ? {
          id: state.userId,
          fullName: state.billTo,
        }
      : {}
  );

  useEffect(() => {
    if (billInfo.invoiceNumber == 0) {
      generateInvoiceNumber();
    }
    getAllMembers();
  }, []);

  useEffect(() => {
    handleCalculateTotal();
  }, [billInfo.taxRate, billInfo.discountRate, items]);

  const generateInvoiceNumber = async () => {
    const res = await api.get("bills/invoice-number");
    setBillInfo({
      ...billInfo,
      invoiceNumber: res.data.invoiceNumber,
    });
  };

  const getAllMembers = async () => {
    const response = await api.get("users");
    setMembers(response.data.users);
  };
  const handleCalculateTotal = () => {
    let subTotal = 0;

    for (let index = 0; index < items.length; index++) {
      let element = items[index];
      subTotal = parseFloat(
        parseFloat(subTotal) +
          parseFloat(element.price).toFixed(2) * parseInt(element.qty)
      ).toFixed(2);
    }

    const taxAmount = parseFloat(
      parseFloat(subTotal) * (billInfo.taxRate / 100)
    ).toFixed(2);
    const discountAmount = parseFloat(
      parseFloat(subTotal) * (billInfo.discountRate / 100)
    ).toFixed(2);

    const total =
      billInfo.taxStatus == "include"
        ? (subTotal - discountAmount).toFixed(2)
        : (subTotal - discountAmount + parseFloat(taxAmount)).toFixed(2);

    setBillInfo({
      ...billInfo,
      subTotal: subTotal,
      taxAmmount: taxAmount,
      discountAmmount: discountAmount,
      total: total,
    });
  };

  const onItemizedItemEdit = (evt) => {
    var editItem = {
      id: evt.id,
      name: evt.name,
      value: evt.value,
    };

    const newItem = items.map((item) => {
      for (var key in item) {
        if (key == editItem.name && item.id == editItem.id) {
          item[key] = editItem.value;
        }
      }
      return item;
    });
    setItems(newItem);

    // handleCalculateTotal();
  };

  const onTaxInfoChanged = (taxRate, taxStatus) => {
    setBillInfo({
      ...billInfo,
      taxRate: taxRate,
      taxStatus: taxStatus,
    });
    // setTaxRate(taxRate);
    // setTaxStatus(taxStatus);
  };

  const handleRowDel = (removeItem) => {
    setItems(items.filter((item) => item.id != removeItem.id));
  };

  const handleAddEvent = () => {
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    const item = {
      id: id,
      name: "",
      price: 1,
      qty: 1,
      startDate: new Date(),
      endDate: new Date(),
    };
    setItems([...items, item]);
  };

  const editField = (event) => {
    setBillInfo({
      ...billInfo,
      [event.target.name]: event.target.value,
    });
  };

  const onCurrencyChange = (selectedOption) => {
    setBillInfo({
      ...billInfo,
      currency: selectedOption,
    });
  };

  const navigate = useNavigate();

  function onBack() {
    navigate("/bills", { replace: true });
  }

  const openModal = (event) => {
    event.preventDefault();
    handleCalculateTotal();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openMarkBillModal = (event) => {
    event.preventDefault();
    handleCalculateTotal();
    setIsOpenMarkModal(true);
  };

  const closeMarkModal = () => {
    setIsOpenMarkModal(false);
  };

  const payNow = async (isOneTime) => {
    confirmAlert({
      title: "Confirm to pay",
      message: "Are you sure you want to pay now?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (isOneTime) handleOneTimePay();
            else handleRecurringPay();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const cancelInvoice = () => {
    confirmAlert({
      title: "Cancel Invoice",
      message: "Do you really want to cancel the invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleCancelInvoice();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleCancelInvoice = async () => {
    try {
      billInfo.items = items;
      const res = await api.put(
        `bills/${billInfo.id}/cancel-invoice`,
        billInfo
      );
      window.location.href = "/bills";
    } catch (error) {
      console.log(error);
    }
  };

  const handleOneTimePay = async () => {
    let intentId = "";
    try {
      if (billInfo.id) {
        setIsShowCancelBtn(true);
        setIsShowSpinner(true);
        const paymentIntentRes = await api.post("create-payment-intent", {
          amount: billInfo.total,
        });

        setPaymentIntent(paymentIntentRes.data.intent);
        intentId = paymentIntentRes.data.intent.id;
        const processPaymentIntent = await api.post("process-payment-intent", {
          paymentIntent: intentId,
        });
        console.log(processPaymentIntent);
        let elapsedTime = 0;
        const interval = 3000; // Check every 1 seconds
        const maxDuration = 30000; // Max duration of 1 minute
        const intervalId = setInterval(async () => {
          elapsedTime += interval;
          const checkPaymentIntent = await api.post("check-payment-intent", {
            paymentIntent: intentId,
          });

          console.log(checkPaymentIntent.data.intent.status);
          if (checkPaymentIntent.data.intent.status == "canceled") {
            clearInterval(intervalId);
            setPaymentIntent(null);
            setIsShowCancelBtn(false);
            toast.error("Your payment is canceled!");
            setIsShowSpinner(false);
          } else if (
            checkPaymentIntent.data.intent.status == "requires_capture"
          ) {
            clearInterval(intervalId);
            const capturesPaymentIntent = await api.post(
              "capture-payment-intent",
              {
                paymentIntent: intentId,
              }
            );

            await api.put(`bills/${billInfo.id}/mark-bill`, {
              id: billInfo.id,
            });

            const transactionData = {
              paymentId: intentId,
              currency: capturesPaymentIntent.data.capture.currency,
              amount:
                parseFloat(capturesPaymentIntent.data.capture.amount) / 100,
              description: capturesPaymentIntent.data.capture.description,
              status:
                capturesPaymentIntent.data.capture.status == "succeeded"
                  ? 1
                  : 0,
              paymentMethod: "stripe",
              customer: billInfo.billTo,
              billNumber: `b_${billInfo.invoiceNumber}`,
              userId: billInfo.toUserId,
            };
            await api.post("transactions", transactionData);
            await api.patch(`users/change-payment/${billInfo.toUserId}`, {
              isRecurringPay: 0,
            });

            const res = await api.post(`update-face-device`, {
              id: billInfo.toUserId,
            });



            setIsShowCancelBtn(false);
            setIsShowSpinner(false);
            toast.success("Success to pay!");
            navigate("/bills", { replace: true });
          } else if (elapsedTime >= maxDuration) {
            clearInterval(intervalId);
            await api.post("cancel-payment-intent", {
              paymentIntent: intentId,
            });
            const transactionData = {
              paymentId: intentId,
              currency: paymentIntentRes.data.intent.currency,
              amount: parseFloat(paymentIntentRes.data.intent.amount) / 100,
              description: paymentIntentRes.data.intent.description,
              status: 0,
              paymentMethod: "stripe",
              customer: billInfo.billTo,
              billNumber: `b_${billInfo.invoiceNumber}`,
              userId: billInfo.toUserId,
            };
            await api.post("transactions", transactionData);
            toast.error("Your payment is canceled! Time out!");
            setPaymentIntent(null);
            setIsShowCancelBtn(false);
            setIsShowSpinner(false);
          }
        }, interval);
      }
    } catch (err) {
      setPaymentIntent(null);
      setIsShowCancelBtn(false);
      setIsShowSpinner(false);
      const errMsg = err.response
        ? err.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    }
  };
  const handleRecurringPay = async () => {
    try {
      if (billInfo.id) {
        setIsShowCancelBtn(true);
        setIsShowSpinner(true);
        const setupIntentRes = await api.post("stripe/create-setup-intent", {
          email: billInfo.billToEmail,
        });
        const { setupIntentId, customerId: newCustomerId } =
          setupIntentRes.data;

        await api.post("stripe/process-setup-intent", {
          setup_intent_id: setupIntentId,
        });

        const checkSetupIntent = await api.post("stripe/check-setup-intent", {
          setup_intent_id: setupIntentId,
        });

        if (checkSetupIntent.data.status == "succeeded") {
          const invoiceResponse = await api.post("stripe/pay-invoice", {
            customerId: newCustomerId,
            amount: parseFloat(billInfo.total) * 100, // Amount in cents
          });
          console.log(invoiceResponse.data.invoice);
          if (invoiceResponse.data.invoice.status == "paid") {
            await api.put(`bills/${billInfo.id}/mark-bill`, {
              id: billInfo.id,
            });
          }
          const transactionData = {
            paymentId: invoiceResponse.data.invoice.payment_intent,
            currency: invoiceResponse.data.invoice.currency,
            amount: parseFloat(invoiceResponse.data.invoice.amount_paid) / 100,
            description: invoiceResponse.data.invoice.description,
            status: invoiceResponse.data.invoice.status == "paid" ? 1 : 0,
            paymentMethod: "stripe",
            customer: billInfo.billTo,
            billNumber: `b_${billInfo.invoiceNumber}`,
            userId: billInfo.toUserId,
          };
          await api.post("transactions", transactionData);

          await api.patch(`users/change-payment/${billInfo.toUserId}`, {
            isRecurringPay: 1,
          });

          //face device update
          const res = await api.post(`update-face-device`, {
            id: billInfo.toUserId,
          });

          
          setIsShowCancelBtn(false);
          setIsShowSpinner(false);
          toast.success("Success to pay!");          
          navigate("/bills", { replace: true });
          
        }
      }
    } catch (err) {
      // setPaymentIntent(null);
      setIsShowCancelBtn(false);
      setIsShowSpinner(false);
      const errMsg = err.response
        ? err.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    }
  };

  // const handleCancelPayment = async () => {
  //   await api.post("cancel-payment-intent", {
  //     paymentIntent: paymentIntent.id,
  //   });
  //   const transactionData = {
  //     paymentId: paymentIntent.id,
  //     currency: paymentIntent.currency,
  //     amount: parseFloat(paymentIntent.amount) / 100,
  //     description: paymentIntent.description,
  //     status: 0,
  //     paymentMethod: "",
  //     customer: billInfo.billTo,
  //     billNumber: `b_${billInfo.invoiceNumber}`,
  //   };
  //   await api.post("transactions", transactionData);
  // };

  const handleMemberChange = (item) => {
    setSelectedMember({
      id: item.id,
      fullName: item.firstName + " " + item.lastName,
      customerNumber: item.customerNumber,
      dinNumber: item.taxId,
    });
    setBillInfo({
      ...billInfo,
      toUserId: item.id,
      billTo: item.firstName + " " + item.lastName,
      billToEmail: item.email,
      billToAddress: item.street + ", " + item.houseNumber + " | " + item.city,
    });
  };

  const handleMemberValue = () => {
    if (selectedMember) {
      return {
        value: selectedMember.id,
        label: selectedMember.fullName,
      };
    }
  };

  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100">
      <Container>
        <Form onSubmit={openModal}>
          <Row>
            <Col md={8} lg={9}>
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="mb-2">
                        <span className="fw-bold">Create Date: </span>
                        <span className="current-date">
                          {billInfo.currentDate
                            ? format(toDate(billInfo.currentDate), "dd.MM.yyyy")
                            : format(new Date(), "dd.MM.yyyy")}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <span className="fw-bold d-block me-2">
                        Due&nbsp;Date:
                      </span>
                      <DatePicker
                        name="dateOfIssue"
                        className="dp"
                        placeholderText="dd.mm.yyyy"
                        dateFormat="dd.MM.yyyy"
                        selected={
                          billInfo.dateOfIssue
                            ? toDate(billInfo.dateOfIssue)
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate = format(date, "yyyy-MM-dd");
                          setBillInfo({
                            ...billInfo,
                            dateOfIssue: formattedDate,
                          });
                        }}
                        value={
                          billInfo.dateOfIssue
                            ? toDate(billInfo.dateOfIssue)
                            : null
                        }
                        disabled={type}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">
                      Invoice&nbsp;Number:&nbsp;
                    </span>
                    <Form.Control
                      type="number"
                      value={billInfo.invoiceNumber}
                      name={"invoiceNumber"}
                      onChange={(event) => editField(event)}
                      min="1"
                      style={{
                        maxWidth: "70px",
                        padding: ".375rem .75rem",
                      }}
                      required="required"
                      disabled={type}
                    />
                  </div>
                </div>
                <hr className="my-4" />
                <Row className="mb-5">
                  <Col>
                    <Form.Label className="fw-bold">Bill to:</Form.Label>
                    <Select
                      id="billTo"
                      className="billToUser"
                      options={members.map((member) => ({
                        value: member.id,
                        label: member.firstName + " " + member.lastName,
                      }))}
                      defaultValue={handleMemberValue()}
                      onChange={(e) => {
                        const item = members.filter(
                          (item) => item.id == e.value
                        );
                        handleMemberChange(item[0]);
                      }}
                      isDisabled={type}
                    />

                    <Form.Control
                      placeholder={"Email address"}
                      value={billInfo.billToEmail}
                      type="email"
                      name="billToEmail"
                      className="my-2"
                      onChange={(event) => editField(event)}
                      autoComplete="email"
                      required="required"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled={type}
                    />
                    <Form.Control
                      placeholder={"Billing address"}
                      value={billInfo.billToAddress}
                      type="text"
                      name="billToAddress"
                      className="my-2"
                      autoComplete="address"
                      onChange={(event) => editField(event)}
                      required="required"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled={type}
                    />
                  </Col>
                  <Col>
                    <Form.Label className="fw-bold">Bill from:</Form.Label>

                    <Form.Control
                      placeholder={"Who is this invoice from?"}
                      rows={3}
                      value={billInfo.billFrom}
                      type="text"
                      name="billFrom"
                      className="my-2"
                      // onChange={(event) => editField(event)}
                      autoComplete="name"
                      required="required"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled
                    />
                    <Form.Control
                      placeholder={"Email address"}
                      value={billInfo.billFromEmail}
                      type="email"
                      name="billFromEmail"
                      className="my-2"
                      // onChange={(event) => editField(event)}
                      autoComplete="email"
                      required="required"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled
                    />
                    <Form.Control
                      placeholder={"Billing address"}
                      value={billInfo.billFromAddress}
                      type="text"
                      name="billFromAddress"
                      className="my-2"
                      autoComplete="address"
                      // onChange={(event) => editField(event)}
                      required="required"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled
                    />
                  </Col>
                </Row>
                <InvoiceItem
                  onTaxInfoChanged={(rate, status) =>
                    onTaxInfoChanged(rate, status)
                  }
                  onItemizedItemEdit={(e) => onItemizedItemEdit(e)}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  currency={billInfo.currency}
                  items={items}
                  disabled={type}
                />
                <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Subtotal:</span>
                      <span>
                        {billInfo.currency}
                        {billInfo.subTotal}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">Discount:</span>
                      <span>
                        <span className="small ">
                          ({billInfo.discountRate || 0}%)
                        </span>
                        {billInfo.currency}
                        {billInfo.discountAmmount || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">
                        Tax({billInfo.taxStatus}):
                      </span>
                      <span>
                        <span className="small ">
                          ({billInfo.taxRate || 0}%)
                        </span>
                        {billInfo.currency}
                        {billInfo.taxAmmount || 0}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                    >
                      <span className="fw-bold">Total:</span>
                      <span className="fw-bold">
                        {billInfo.currency}
                        {billInfo.total || 0}
                      </span>
                    </div>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Form.Label className="fw-bold">Notes:</Form.Label>
                <Form.Control
                  placeholder="Thanks for your business!"
                  name="notes"
                  value={billInfo.notes}
                  onChange={(event) => editField(event)}
                  as="textarea"
                  className="my-2"
                  rows={1}
                  disabled={type}
                />
              </Card>
            </Col>
            <Col md={4} lg={3}>
              <div className="sticky-top pt-md-3 pt-xl-4">
                <Button
                  variant="primary"
                  type="button"
                  className="d-block w-100"
                  onClick={onBack}
                >
                  Back
                </Button>
                {type && billInfo.total > 0 && (
                  <Button
                    variant="primary"
                    type="button"
                    className="d-block w-100 mt-3"
                    onClick={() => cancelInvoice()}
                  >
                    Cancel Invoice
                  </Button>
                )}

                {!type && (
                  <Button
                    variant="primary"
                    type="submit"
                    className="d-block w-100 mt-3"
                  >
                    Review Invoice
                  </Button>
                )}

                {billInfo.id != 0 && billInfo.status == 0 && (
                  <Button
                    variant="primary"
                    type="button"
                    className="d-block w-100 mt-3"
                    onClick={openMarkBillModal}
                  >
                    Mark the Bill
                  </Button>
                )}

                {billInfo.id != 0 &&
                  billInfo.status == 0 &&
                  !isShowCancelBtn && (
                    <Button
                      variant="danger"
                      type="button"
                      className="d-block w-100 mt-3"
                      onClick={() => payNow(1)}
                    >
                      One time Pay
                    </Button>
                  )}

                {billInfo.id != 0 &&
                  billInfo.status == 0 &&
                  !isShowCancelBtn && (
                    <Button
                      variant="danger"
                      type="button"
                      className="d-block w-100 mt-3"
                      onClick={() => payNow(0)}
                    >
                      Recurring Pay
                    </Button>
                  )}

                {billInfo.id != 0 &&
                  billInfo.status == 0 &&
                  isShowCancelBtn && (
                    <Button
                      variant="danger"
                      type="button"
                      className="d-block w-100 mt-3"
                      onClick={() => {
                        // cancelInvoice = true;
                        // setIsCancel(true);
                        // setIsShowCancelBtn(false);
                        // setIsShowSpinner(false);
                        navigate(0);
                      }}
                    >
                      Cancel Payment
                    </Button>
                  )}
                {isShowSpinner && (
                  <div className="spinner-wrapper">
                    <RotatingLines
                      visible={true}
                      height="40"
                      width="40"
                      color="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                )}

                <InvoiceModal
                  showModal={isOpen}
                  closeModal={closeModal}
                  info={billInfo}
                  items={items}
                  user={selectedMember}
                />
                <MarkBillModal
                  showModal={isOpenMarkModal}
                  closeModal={closeMarkModal}
                  info={billInfo}
                />

                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Currency:</Form.Label>
                  <Form.Select
                    onChange={(event) => onCurrencyChange(event.target.value)}
                    className="btn btn-light my-1"
                    aria-label="Change Currency"
                    disabled={type}
                  >
                    <option value="€">EUR (EURO)</option>
                    <option value="$">USD (United States Dollar)</option>
                    <option value="£">GBP (British Pound Sterling)</option>
                    <option value="¥">JPY (Japanese Yen)</option>
                    <option value="$">CAD (Canadian Dollar)</option>
                    <option value="$">AUD (Australian Dollar)</option>
                    <option value="$">SGD (Signapore Dollar)</option>
                    <option value="¥">CNY (Chinese Renminbi)</option>
                    <option value="₿">BTC (Bitcoin)</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Tax rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="taxRate"
                      type="number"
                      value={billInfo.taxRate}
                      onChange={(e) => {
                        setBillInfo({
                          ...billInfo,
                          taxRate: e.target.value,
                        });
                      }}
                      className="bg-white border"
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled={type}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discountRate"
                      type="number"
                      value={billInfo.discountRate}
                      onChange={(event) => editField(event)}
                      className="bg-white border"
                      placeholder="0.0"
                      min="0.00"
                      step="0.01"
                      max="100.00"
                      style={{
                        padding: ".375rem .75rem",
                      }}
                      disabled={type}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default InvoiceForm;
