import React from "react";
import { Container, Head, ContentWrapper, Navigation } from "./styles";
import { NavLink, Outlet } from "react-router-dom";
const Settings = () => {
  return (
    <Container>
      <Head>
        <h1>Settings</h1>
      </Head>
      <ContentWrapper>
        <div className="side-bar-menu">
          <Navigation>
            <nav>
              <NavLink
                to="company"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                {" "}
                Company
              </NavLink>
              <NavLink
                to="payment"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Payment
              </NavLink>
              <NavLink
                to="tax"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Tax
              </NavLink>

              <NavLink
                to="security"
                className={({ isActive }) =>
                  isActive ? "active nav-link" : "nav-link"
                }
              >
                Security
              </NavLink>
            </nav>
          </Navigation>
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </ContentWrapper>
    </Container>
  );
};

export default Settings;
