import React, { useState, useEffect } from "react";
import {
  MdAdd,
  MdCheckCircle,
  MdDownload,
  MdOutlineSend,
} from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";

import api from "../../services/api";
import {
  formatDate,
  getInvoiceState,
  profileImageEndPoint,
} from "../../utils/utils";
import { Container, Head, ContentWrapper, Input, Selec } from "./styles";

import ConfirmAlert from "../../components/ConfirmAlert";
import MemberManagementForm from "../MemberManagementForm";
import CustomModal from "../../components/Modal/CustomModal";
import BillListModal from "../../components/Invoice/BillListModal";

const MemberManagement = () => {
  const [memberManagements, setMemberManagements] = useState([]);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isOpenBillModal, setIsOpenBillModal] = useState(false);
  const [billList, setBillList] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [statusType, setStatusType] = useState(0);

  useEffect(() => {
    loadMemberManagement();
  }, [isActive, statusType]);

  const loadMemberManagement = async () => {
    const response = await api.get("enrollments", {
      params: {
        statusType: statusType,
        isActive: isActive,
      },
    });
    setMemberManagements(response.data);
    setData(response.data);
    setIsLoading(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        "&:nth-child(1)": {
          justifyContent: "center",
        },
        "&:nth-child(2)": {
          justifyContent: "center",
        },
        "&:nth-child(6)": {
          width: "300px",
        },
        "&:nth-child(7)": {
          justifyContent: "center",
        },
        "&:nth-child(8)": {
          justifyContent: "center",
        },
        "&:nth-child(9)": {
          justifyContent: "center",
        },
        "&:nth-child(11)": {
          justifyContent: "center",
        },
        "&:nth-child(12)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };

  const columns = [
    {
      name: "Avatar",
      width: "120px",
      style: { justifyContent: "center" },
      cell: (row) => (
        <img
          className="avatar"
          src={`${profileImageEndPoint()}/${row.user.customerNumber}`}
        />
      ),
      ignoreRowClick: true,
    },
    {
      name: "Invoice Status",
      // width: "80px",
      style: { justifyContent: "center" },
      cell: (row) => {
        const state = getInvoiceState(row.bills);
        const color =
          state == 0 ? "#dddddd" : state == 1 ? "#ff0000" : "#42cb59";
        return (
          <MdCheckCircle
            size={20}
            color={color}
            onClick={() => showInvoiceModal(row.bills)}
          />
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const stateA = getInvoiceState(rowA.bills);
        const stateB = getInvoiceState(rowB.bills);
        // const dateA = new Date(rowA.startDate);
        // const dateB = new Date(rowB.startDate);
        return stateA - stateB;
      },
      ignoreRowClick: true,
    },
    {
      name: "First Name",
      selector: (row) => row.user.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.user.lastName,
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => row.user.customerNumber,
      sortable: true,
    },
    {
      width: "300px",
      name: "Membership",
      selector: (row) => row.membership.title,
      sortable: true,
    },
    {
      name: "Start Date",
      style: { justifyContent: "center" },
      selector: (row) => formatDate(row.startDate),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.startDate);
        const dateB = new Date(rowB.startDate);
        return dateA - dateB;
      },
    },
    {
      name: "Next Invoice",
      style: { justifyContent: "center" },
      selector: (row) => formatDate(row.endDate),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.endDate);
        const dateB = new Date(rowB.endDate);
        return dateA - dateB;
      },
    },
    {
      name: "Cancel Date",
      style: { justifyContent: "center" },
      selector: (row) => formatDate(row.cancelDate),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.cancelDate);
        const dateB = new Date(rowB.cancelDate);
        return dateA - dateB;
      },
    },
    {
      name: "Status",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) =>
        row.active ? (
          <MdCheckCircle size={20} color="#42cb59" />
        ) : (
          <MdCheckCircle size={20} color="#dddddd" />
        ),
      ignoreRowClick: true,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return rowA.active - rowB.active;
      },
    },
    {
      name: "Download",
      width: "80px",
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => downloadMemberManagement(row.id)}
          id={row.id}
        >
          <MdDownload size={20} color="#4d85ee" />
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Email",
      width: "80px",
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => sendEmail(row.id)}
          id={row.id}
        >
          <MdOutlineSend size={20} color="#4d85ee" />
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      width: "80px",
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => handleEditMemberManagement(row.id)}
          id={row.id}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      width: "80px",
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn deleteBtn"
          onClick={() =>
            ConfirmAlert("enrollments", row.id, (state) => {
              if (state) onRemoveMemberManagements(row.id);
            })
          }
          id={row.id}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
    },
  ];

  const handleEditMemberManagement = async (enrollmentId) => {
    try {
      const response = await api.get("enrollments/enrollment-by-id", {
        params: {
          id: enrollmentId,
        },
      });
      setItem(response.data.data);
      setIsOpen(true);
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error.message
        : "Faile to get enrollment data!";
      toast.error(errMsg);
    }
  };

  const downloadMemberManagement = async (enrollmentId) => {
    try {
      const pdfRes = await api.get("enrollments/enrollment-create-pdf", {
        params: {
          id: enrollmentId,
        },
      });

      if (pdfRes.data.status && pdfRes.data.fileName) {
        const fileName = pdfRes.data.fileName;
        const response = await api.get("enrollments/enrollment-download", {
          params: {
            fileName: fileName,
          },
          responseType: "blob", // Important for downloading files
        });

        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error
        : "Faile to get enrollment data!";
      toast.error(errMsg);
    }
    // try {

    // } catch (error) {
    //   console.error("Error downloading the file:", error);
    // }
  };

  const sendEmail = async (enrollmentId) => {
    try {
      const response = await api.get("enrollments/enrollment-send-email", {
        params: {
          id: enrollmentId,
        },
      });
      if (response.data.status && response.data.toEmail) {
        toast.success(`Send email to ${response.data.toEmail}`);
      }
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error.message
        : "Faile to get enrollment data!";
      toast.error(errMsg);
    }
  };

  const handleAddMemberManagement = () => {
    setItem(null);
    setIsOpen(true);
  };

  const onRemoveMemberManagements = (id) => {
    setMemberManagements(memberManagements.filter((t) => t.id !== id));
    setData(data.filter((t) => t.id !== id));
  };

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(memberManagements);
    } else {
      const newRows = memberManagements.filter((row) => {
        if (
          row.user.firstName
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          row.user.lastName
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
          return row;
      });

      setData(newRows);
    }
  };

  const closeBillModal = () => {
    setIsOpenBillModal(false);
  };

  const showInvoiceModal = (items) => {
    setBillList(items);
    setIsOpenBillModal(true);
  };

  const activeOptions = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Active",
    },
  ];

  const statusTypeOptions = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Unpaid",
    },
  ];
  return (
    <Container>
      <Head>
        <h1>Member Management</h1>
        <div className="filterBar">
          <Selec
            id="paymentType"
            options={activeOptions}
            defaultValue={activeOptions[isActive]}
            onChange={(e) => {
              setIsActive(e.value);
            }}
          />
          <Selec
            id="statusType"
            options={statusTypeOptions}
            defaultValue={statusTypeOptions[statusType]}
            onChange={(e) => {
              setStatusType(e.value);
            }}
          />

          <button
            className="defaultBtn"
            type="button"
            onClick={() => handleAddMemberManagement()}
          >
            <MdAdd size={20} className="mdAdd" />
            Add
          </button>
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
      <CustomModal isOpen={isOpen}>
        <MemberManagementForm
          closeModal={() => setIsOpen(false)}
          memberManagement={item}
          from={"page"}
        />
      </CustomModal>
      <BillListModal
        showModal={isOpenBillModal}
        closeModal={closeBillModal}
        bills={billList}
      />
    </Container>
  );
};

export default MemberManagement;
