import styled from "styled-components";
import { darken } from "polished";
export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
  min-width: 850px;

  .active {
    color: #444444;
  }
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    margin-left: 5px;
    width: 70%;
  }

  p {
    font-weight: bold;
    color: #ee4d64;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 15px;
    text-align: left;
  }

  aside {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;
  }

  nav {
    display: flex;

    a {
      font-weight: bold;
      margin-left: 20px;
      font-size: 15px;
      color: #999999;
      text-align: left;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 5px;
  padding-left: 5px;

  .openDoorBtn,
  .closeDoorBtn {
    background: #1c1c1c;
    color: #fff;
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }

  div {
    strong {
      display: block;
      font-size: 14px;
      color: #666666;
      text-align: left;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 14px;
      color: #de3b3b;
      text-align: right;
      margin-left: 40px;
    }
  }
`;
