import React, { useState, useEffect } from "react";
import { Container, Head, DivForm, Form } from "./styles";
import Input from "../../../components/Form/Input";
import { MdCheck } from "react-icons/md";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Security = () => {
  // const [isLoading, setIsLoading] = React.useState(true);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleSave = async () => {
    try {
      if (password.newPassword != password.confirmPassword) {
        toast.error("new password mismatch!");
        return;
      }
      const response = await api.post("reset-password", password);
      toast.success("new password is setted successfully!");
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    }
  };
  return (
    <Container>
      <Form
        /* schema={} */ onSubmit={handleSave}
        encType={"multipart/form-data"}
      >
        <Head>
          <div>Password Reset</div>
          <div>
            <button className="defaultBtn" type="submit">
              <MdCheck size={20} className="mdAdd" />
              SAVE
            </button>
          </div>
        </Head>
        <hr className="my-3" />
        <DivForm>
          <div className="contentWrap">
            <div>
              <ul>
                <li>
                  <strong>Old Password</strong>
                  <Input
                    name="oldPassword"
                    type="password"
                    value={password.oldPassword}
                    onChange={(e) => {
                      setPassword({
                        ...password,
                        oldPassword: e.target.value,
                      });
                    }}
                  />
                </li>

                <li>
                  <strong>New Password</strong>
                  <Input
                    name="newPassword"
                    type="password"
                    value={password.newPassword}
                    onChange={(e) => {
                      setPassword({
                        ...password,
                        newPassword: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Confirm Password</strong>
                  <Input
                    name="confirmPassword"
                    type="password"
                    value={password.confirmPassword}
                    onChange={(e) => {
                      setPassword({
                        ...password,
                        confirmPassword: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </DivForm>
      </Form>
    </Container>
  );
};

export default Security;
