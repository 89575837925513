import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate, useOutlet } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import logo from "../../assets/logo.png";

import { Content } from "./styles";
import { store } from "../../store";
import { signOut } from "../../store/modules/auth/actions";
const Mobile = () => {
  const { signed } = store.getState().auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!signed) {
      navigate("/", { state: { from: "mobile" } });
    }
  }, []);

  const handleSignOut = () => {
    dispatch(signOut());
  };
  const openDoor = async (isInDoor) => {
    try {
      const response = await api.post("/open-close-door", {
        isInDoor: isInDoor,
      });
      if (response.data.status) {
        toast.success(`${isInDoor ? "In" : "Out"} Door open successfully!`);
      } else {
        toast.error(`${isInDoor ? "In" : "Out"} door open failed!`);
      }
    } catch (error) {
      const errMsg = error.response
        ? error.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    }
  };
  return (
    <Content>
      <img src={logo} alt="MARAM Fitwork" />

      <div>
        <button onClick={() => openDoor(1)}>Open Door(In)</button>
        <button onClick={() => openDoor(0)}>Open Door(Out)</button>
        <button onClick={handleSignOut}> Log out</button>
      </div>
    </Content>
  );
};

export default Mobile;
