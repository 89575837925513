import React, { useEffect } from "react";
import { useNavigate, Navigate, useOutlet } from "react-router-dom";
import { store } from "../../store";
import { ProtectedWrapper } from "./styles";
import Header from "../Header";

const ProtectedLayout = () => {
  const { signed } = store.getState().auth;
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signed) {
      // navigate("/", { replace: true });
      window.location.href = "/";
    }
  }, []);

  return signed ? (
    <ProtectedWrapper>
      <Header />
      {outlet}
    </ProtectedWrapper>
  ) : (
    <></>
  );
};

export default ProtectedLayout;
