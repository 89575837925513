import React, { useState, useEffect } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toDate } from "date-fns-tz";
import { CSVLink } from "react-csv";
import { parseISO, format } from "date-fns";
import api from "../../services/api";
import { Container, Head, ContentWrapper, Input, Selec } from "./styles";
import {
  formatedFinacial,
  formatDate,
  profileImageEndPoint,
} from "../../utils/utils";

const Payments = () => {
  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [statusType, setStatusType] = useState(null);

  useEffect(() => {
    loadTransactions();
  }, [startDate, endDate, paymentType, statusType]);

  const loadTransactions = async () => {
    const response = await api.get("/transactions", {
      params: {
        statusType: statusType,
        paymentType: paymentType,
        startDate: startDate,
        endDate: endDate,
      },
    });

    setTransactions(response.data.transactions);
    setData(response.data.transactions);
    setIsLoading(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        justifyContent: "right",
        "&:nth-child(1)": {
          justifyContent: "center",
        },
        "&:nth-child(2)": {
          justifyContent: "center",
        },
        "&:nth-child(3)": {
          justifyContent: "left",
        },
        "&:nth-child(4)": {
          justifyContent: "right",
        },
        "&:nth-child(5)": {
          justifyContent: "center",
        },
        "&:nth-child(6)": {
          justifyContent: "center",
        },
        "&:nth-child(7)": {
          justifyContent: "center",
        },
        "&:nth-child(8)": {
          justifyContent: "center",
        },
        "&:nth-child(9)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
        justifyContent: "right",
      },
    },
  };
  const columns = [
    {
      name: "Avatar",
      width: "120px",

      style: { justifyContent: "center" },
      cell: (row) => (
        <img
          src={`${profileImageEndPoint()}/${row.customerNumber}`}
          className="avatar"
        />
      ),
      ignoreRowClick: true,
    },
    {
      width: "150px",
      name: "Customer No",
      style: { justifyContent: "center" },
      selector: (row) => row.customerNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const customerNumberA = parseInt(
          rowA.customerNumber.replace("MG-", "")
        );
        const customerNumberB = parseInt(
          rowB.customerNumber.replace("MG-", "")
        );
        return customerNumberA - customerNumberB;
      },
    },
    {
      name: "Name",
      style: { justifyContent: "left" },
      selector: (row) => row.customer,
      sortable: true,
    },
    {
      width: "100px",
      name: "Amount",
      style: { justifyContent: "center" },
      selector: (row) => formatedFinacial(row.amount) + "€",
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const amountA = parseInt(rowA.amount);
        const amountB = parseInt(rowB.amount);
        return amountA - amountB;
      },
    },
    {
      name: "Bill Number",
      selector: (row) => row.billNumber,
      style: { justifyContent: "center" },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const billNumberA = parseInt(rowA.billNumber.replace("b_", ""));
        const billNumberB = parseInt(rowB.billNumber.replace("b_", ""));
        return billNumberA - billNumberB;
      },
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.paidDate),
      style: { justifyContent: "center" },
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.paidDate);
        const dateB = new Date(rowB.paidDate);
        return dateA - dateB;
      },
    },

    {
      name: "Payment Type",
      selector: (row) => row.paymentMethod,
      style: { justifyContent: "center" },
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : row.paymentId),
    },
    {
      name: "Status",
      width: "120px",
      style: { justifyContent: "center" },
      cell: (row) =>
        row.status ? (
          <span>
            <MdCheckCircle size={20} color="#42cb59" />
          </span>
        ) : (
          <span>
            <MdCancel size={20} color="#ff0000" />
          </span>
        ),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return rowA.status - rowB.status;
      },
      ignoreRowClick: true,
    },
  ];

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(transactions);
    } else {
      const newRows = transactions.filter((row) => {
        if (
          row.customer
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          row.billNumber
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
          return row;
      });

      setData(newRows);
    }
  };
  const headers = [
    { label: "CustomerNo", key: "customerNumber" },
    { label: "Name", key: "customer" },
    { label: "Amount", key: "amount" },
    { label: "BillNumber", key: "billNumber" },
    { label: "Date", key: "paidDate" },
    { label: "PaymentType", key: "paymentMethod" },
    { label: "Description", key: "description" },
    { label: "Status", key: "status" },
  ];
  const paymentTypeOptions = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "banktransfer",
      label: "Bank Transfer",
    },
    {
      value: "cash",
      label: "Cash",
    },

    {
      value: "direct debt",
      label: "Direct debt",
    },
    {
      value: "credit card",
      label: "Credit Card",
    },
    {
      value: "stripe",
      label: "Stripe",
    },
  ];

  const statusTypeOptions = [
    {
      value: 2,
      label: "All",
    },
    {
      value: 1,
      label: "Success",
    },
    {
      value: 0,
      label: "Failed",
    },
  ];

  return (
    <Container>
      <Head>
        <h1>Transactions</h1>
        <div className="filterBar">
          <DatePicker
            name="startDate"
            className="dp"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={startDate ? toDate(startDate) : null}
            onChange={(date) => {
              const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
              setStartDate(formattedDate);
            }}
            selectsStart
            startDate={startDate ? toDate(startDate) : null}
            endDate={endDate ? toDate(endDate) : null}
          />
          -
          <DatePicker
            name="endDate"
            className="dp"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={endDate ? toDate(endDate) : null}
            onChange={(date) => {
              const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
              setEndDate(formattedDate);
            }}
            selectsEnd
            startDate={startDate ? toDate(startDate) : null}
            endDate={endDate ? toDate(endDate) : null}
            minDate={startDate ? toDate(startDate) : null}
          />
          <Selec
            id="paymentType"
            options={paymentTypeOptions}
            defaultValue={paymentTypeOptions[paymentType]}
            onChange={(e) => {
              setPaymentType(e.value);
            }}
          />
          <Selec
            id="statusType"
            options={statusTypeOptions}
            defaultValue={statusTypeOptions[statusType]}
            onChange={(e) => {
              setStatusType(e.value);
            }}
          />
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <CSVLink
            data={data}
            headers={headers}
            filename="data.csv"
            className="custom-csv-button"
          >
            Export to CSV
          </CSVLink>
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
    </Container>
  );
};

export default Payments;
