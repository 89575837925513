import React, { useState, useRef, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiCheck } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";
import Webcam from "react-webcam";

const CameraModal = ({ isShow, closeModal, getProfileImage }) => {
  const webcamRef = useRef(null);
  const croppedImageRef = useRef(null);
  const [preview, setPreview] = useState("");
  const [isCaptureMode, setIsCaptureMode] = useState(1);
  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set the canvas size to the desired cropped dimensions
    canvas.width = 480;
    canvas.height = 640;

    // Create an image element to load the screenshot
    const img = new Image();
    img.onload = () => {
      // Calculate the position to start cropping from
      const startX = (img.width - 480) / 2;
      const startY = (img.height - 640) / 2;

      // Draw the image onto the canvas, cropping it to 480x640
      ctx.drawImage(img, startX, startY, 480, 640, 0, 0, 480, 640);

      // Check and adjust image size
      let quality = 1.0;
      let resizedImageSrc = canvas.toDataURL("image/jpeg", quality);

      while (resizedImageSrc.length > 300 * 1024 && quality > 0.5) {
        quality -= 0.1;
        resizedImageSrc = canvas.toDataURL("image/jpeg", quality);
      }

      // If image is still larger than 300KB, reduce its dimensions
      if (resizedImageSrc.length > 300 * 1024) {
        const scaleFactor = Math.sqrt((250 * 1024) / resizedImageSrc.length);
        canvas.width *= scaleFactor;
        canvas.height *= scaleFactor;
        ctx.drawImage(
          img,
          startX,
          startY,
          480,
          640,
          0,
          0,
          canvas.width,
          canvas.height
        );
        resizedImageSrc = canvas.toDataURL("image/jpeg", quality);
      }

      // setCroppedImageSrc(resizedImageSrc);
      console.log(
        "Final Image Size:",
        (resizedImageSrc.length / 1024).toFixed(2),
        "KB"
      );
      croppedImageRef.current = resizedImageSrc;
    };
    img.src = imageSrc;
    setPreview(imageSrc);
    setIsCaptureMode(0);
  }, [webcamRef]);

  const handleGetPicture = async () => {
    if (croppedImageRef.current) {
      const imgResponse = await fetch(croppedImageRef.current);
      const imgBlob = await imgResponse.blob();
      getProfileImage(imgBlob, croppedImageRef.current);
    }
  };

  return (
    <div>
      <Modal show={isShow} onHide={closeModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Profile Image</Modal.Title>
        </Modal.Header>
        <div id="invoiceCapture">
          <div className="p-4">
            <Row className="mb-4">
              {isCaptureMode ? (
                <Webcam
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1.0}
                />
              ) : (
                <img src={preview} />
              )}
            </Row>
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              {!isCaptureMode ? (
                <Button
                  variant="dark"
                  className="d-block w-100"
                  onClick={() => setIsCaptureMode(1)}
                >
                  <BiCheck
                    style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                    className="me-2"
                  />
                  Open Camera
                </Button>
              ) : (
                <Button
                  variant="dark"
                  className="d-block w-100"
                  onClick={() => capture()}
                >
                  <BiCheck
                    style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                    className="me-2"
                  />
                  Capture Photo
                </Button>
              )}
            </Col>
            <Col md={6}>
              <Button
                variant="dark"
                className="d-block w-100 mt-3 mt-md-0"
                onClick={handleGetPicture}
              >
                <BiCheck
                  style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                  className="me-2"
                />
                Ok
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

CameraModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object,
};

export default CameraModal;
