import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  MdCheck,
  MdKeyboardArrowLeft,
  MdClose,
  MdAdd,
  MdEdit,
} from "react-icons/md";
import Input from "../../components/Form/Input";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import api from "../../services/api";
import { toDate } from "date-fns-tz";
import { format } from "date-fns";

import { formatDate } from "../../utils/utils";
import MemberManagementForm from "../MemberManagementForm";
import CustomModal from "../../components/Modal/CustomModal";
import {
  Container,
  Head,
  DivForm,
  Form,
  Footer,
  DPicker,
  DTimePicker,
  Selec,
  ContentWrapper,
  LoadingWraper,
} from "./styles";
import CameraModal from "../../components/Modal/CameraModal";

const MemberForm = ({ closeModal, member }) => {
  const [user, setUser] = useState({
    id: member ? member.id : "",
    customerId: member ? member.customerId : "",
    faceId: member ? member.faceId : "",
    email: member ? member.email : "",
    password: member ? member.password : "",
    role: member ? member.role : "",
    firstName: member ? member.firstName : "",
    lastName: member ? member.lastName : "",
    customerNumber: member ? member.customerNumber : "",
    dateOfBirth: member && member.dateOfBirth ? member.dateOfBirth : null,
    gender: member ? member.gender : 0,
    customerStatus: member ? member.customerStatus : 1,
    street: member ? member.street : "",
    houseNumber: member ? member.houseNumber : "",
    zip: member ? member.zip : "",
    city: member ? member.city : "",
    telPrivate: member ? member.telPrivate : "",
    telPrivateMobile: member ? member.telPrivateMobile : "",
    lastCheckinTime:
      member && member.lastCheckinTime ? member.lastCheckinTime : undefined,
    iban: member ? member.iban : "",
    bic: member ? member.bic : "",
    accountHolder: member ? member.accountHolder : "",
    taxId: member ? member.taxId : "",
    isRecurringPay: member ? member.isRecurringPay : 0,
    imageUrl: member ? member.imageUrl : null,
  });

  const [enrollment, setEnrollment] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isShowCameraModal, setIsShowCameraModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaveLoading, setIsSaveLoading] = React.useState(false);
  const [item, setItem] = useState(null);
  useEffect(() => {
    if (!member) {
      getCustomerNumber();
    } else {
      getEnrollmentData();
    }
  }, [member]);

  async function getCustomerNumber() {
    const res = await api.get("users/gen/customer-number");
    setUser({
      ...user,
      customerNumber: res.data.customerNumber,
      email: res.data.customerNumber + "@teide-admin2.eu",
    });
    setIsLoading(false);
  }
  async function getEnrollmentData() {
    const enrollmentRes = await api.get(
      `enrollments/enrollment-by-userid/${member.id}`
    );
    setEnrollment(enrollmentRes.data);
    setIsLoading(false);
  }

  const defaultImageUrl = user.imageUrl
    ? `data:image/png;base64,${user.imageUrl}`
    : "/default.png";
  const [preview, setPreview] = useState(defaultImageUrl);
  const [file, setFile] = useState(null);
  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        paddingLeft: "0px",
        paddingRight: "2px",
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        color: "#666666",
        paddingLeft: "0px",
        paddingRight: "2px",
      },
    },
  };

  const columns = [
    {
      name: "Tairf",
      cell: (row) => (
        <label
          className={row.active ? "membershipLabel active" : "membershipLabel"}
        >
          {row.membership.title}
        </label>
      ),
    },
    {
      width: "80px",
      name: "Start",
      cell: (row) => (
        <label
          className={row.active ? "membershipLabel active" : "membershipLabel"}
        >
          {formatDate(row.startDate)}
        </label>
      ),
    },

    {
      width: "80px",
      name: "End",
      cell: (row) => (
        <label
          className={row.active ? "membershipLabel active" : "membershipLabel"}
        >
          {formatDate(row.endDate)}
        </label>
      ),
    },

    {
      width: "30px",
      cell: (row) => (
        <button
          className="actionBtn"
          type="button"
          onClick={() => handleEditmMemberManagement(row)}
          id={row.id}
        >
          <MdEdit size={20} className="mdEdit" />
        </button>
      ),
      ignoreRowClick: true,
    },
  ];

  const handleEditmMemberManagement = (memberManagement) => {
    setItem(memberManagement);
    setIsOpen(true);
  };

  const handleAddmMemberManagement = () => {
    let data = {};
    data.user = {
      id: member.id,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      street: member.street,
      houseNumber: member.houseNumber,
      city: member.city,
    };
    setItem(data);
    setIsOpen(true);
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    if (member) {
      try {
        const formData = new FormData();
        if (file) {
          if (file.size > 1024 * 300) {
            console.log(file.size);
            toast.error("Image size is not larger than 300K");
            return;
          }
          formData.append("file", file, user.customerNumber + ".jpg");
        }

        const payload = user;
        delete payload.imageUrl;
        formData.append("user", JSON.stringify(payload));
        const response = await api.patch("users/update-user-by-id", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data && response.data.faceDeviceStatus) {
          const faceDeviceStatus = JSON.parse(response.data.faceDeviceStatus);
          console.log(faceDeviceStatus);
          showFaceDeviceStatus(faceDeviceStatus);
        }
         setTimeout(() => {
          window.location.href = "/members";
         }, 2000);
         
        

        toast.success("Member edited successfully!");
        setIsSaveLoading(false);
      } catch (err) {
        const errMsg = err.response
          ? err.response.data.error.message
          : "Something went wrong!";
        toast.error(errMsg);
        setIsSaveLoading(false);
      }
    } else {
      try {
        const formData = new FormData();
        if (!file) {
          toast.error("Please add profile image!");
          return;
        }
        if (file.size > 1024 * 300) {
          console.log(file.size);
          toast.error("Image size is not larger than 300K");
          return;
        }
        formData.append("file", file, user.customerNumber + ".jpg");
        formData.append("user", JSON.stringify(user));
        const response = await api.post("users", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data && response.data.faceDeviceStatus) {
          const faceDeviceStatus = JSON.parse(response.data.faceDeviceStatus);
          console.log(faceDeviceStatus);
          showFaceDeviceStatus(faceDeviceStatus);
        }
        setTimeout(() => {
          window.location.href = "/members";
         }, 2000);
         
        toast.success("Member added successfully!");
        setIsSaveLoading(false);
      } catch (err) {
        const errMsg = err.response
          ? err.response.data.error.message
          : "Something went wrong!";
        toast.error(errMsg);
        setIsSaveLoading(false);
      }
    }
  };

  const showFaceDeviceStatus = (faceDeviceStatus) => {
    if (!faceDeviceStatus || faceDeviceStatus.length == 0) return;
    for (let index = 0; index < faceDeviceStatus.length; index++) {
      const element = faceDeviceStatus[index];
      if (element.status) {
        toast.success(`FaceDevice: ${element.message}`);
      } else {
        const failCode = element.failCode.split(",");
        failCode.forEach((code) => {
          toast.error(`FaceDevice: ${getFaceDeviceErrorMessage(code)}`);
        });
      }
    }
  };

  const getFaceDeviceErrorMessage = (failCode) => {
    let errMsg = "";
    switch (failCode) {
      case "1":
        errMsg = "Incorrect userId format";
        break;
      case "2":
        errMsg = "Send photos incorrectly formatted";
        break;
      case "3":
        errMsg = "Photo registration error";
        break;
      case "4":
        errMsg = "Storage errors";
        break;
      case "5":
        errMsg = "Send face data incorrectly";
        break;
      case "6":
        errMsg = "Face data storage error";
        break;
      case "7":
        errMsg = "The fingerprint data is incorrectly issued";
        break;
      case "8":
        errMsg = "Issue palm print data error";
        break;
      case "9":
        errMsg =
          "Duplicate registration (registration information already exists on another user)";
        break;
      case "10":
        errMsg = "User profile image don't exist";
        break;
      case "11":
        errMsg = "Unknown Error";
        break;
      default:
        break;
    }

    return errMsg;
  };

  const handleGoBack = () => {
    closeModal();
  };

  const handlePreview = useCallback((e) => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreview("/default.png");
    }
    if (file.size > 1024 * 300) {
      toast.error("Image size is not larger than 300K");
      return;
    }
    const previewURL = URL.createObjectURL(file);

    setFile(file);
    setPreview(previewURL);
  }, []);

  const genderOptions = [
    {
      value: 0,
      label: "Man",
    },
    {
      value: 1,
      label: "Women",
    },
  ];

  const isRecurringOptions = [
    {
      value: 0,
      label: "One Time",
    },
    {
      value: 1,
      label: "Recurring",
    },
  ];
  const statusOptions = [
    {
      value: 0,
      label: "Inactive",
    },
    {
      value: 1,
      label: "Active",
    },
  ];

  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setUser({
      ...user,
      dateOfBirth: formattedDate,
    });
  };

  const getProfileImage = (imgBlob, imgSrc) => {
    setPreview(imgSrc);
    setFile(new File([imgBlob], user.customerNumber + ".jpg"));
    setIsShowCameraModal(false);
  };
  return (
    <Container>
      <Form
        // /* schema={}
        encType={"multipart/form-data"}
      >
        <Head>
          <h1>{member ? "Edit member form" : "Member form"}</h1>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdClose size={20} className="mdAdd" />
            </button>
          </div>
        </Head>
        <DivForm>
          <div className="profileImgWrap">
            Profile Image
            <div className="avatarWrap">
              <img width={300} height={400} src={preview} />
            </div>
            <Input
              className="fileInput"
              name="imageUrl"
              type="file"
              accept="image/*"
              onChange={handlePreview}
            />
            <div className="btnContainer">
              <button
                type="button"
                onClick={() => {
                  setIsShowCameraModal(true);
                }}
              >
                Open Camera
              </button>
            </div>
            <hr></hr>
            <ContentWrapper>
              <Head>
                <div className="membershipTitle">Membership</div>
                <button
                  className="defaultBtn backBtn"
                  type="button"
                  onClick={() => handleAddmMemberManagement()}
                >
                  <MdAdd size={20} className="mdAdd" />
                </button>
              </Head>
              <DataTable
                columns={columns}
                data={enrollment}
                progressPending={isLoading}
                progressComponent={
                  <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color="#666666"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                }
                customStyles={customStyles}
              />
            </ContentWrapper>
            <CustomModal isOpen={isOpen}>
              <MemberManagementForm
                closeModal={() => {
                  getEnrollmentData();
                  setIsOpen(false);
                }}
                memberManagement={item}
                from={"modal"}
              />
            </CustomModal>
            <CameraModal
              isShow={isShowCameraModal}
              closeModal={() => setIsShowCameraModal(false)}
              getProfileImage={(imgBlob, preview) =>
                getProfileImage(imgBlob, preview)
              }
            />
          </div>
          <div className="profileContentWrap">
            <div>
              <ul>
                <li>
                  Number
                  <Input
                    name="customerNumber"
                    type="text"
                    value={user.customerNumber}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        customerNumber: e.target.value,
                      });
                    }}
                    disabled
                  />
                </li>
                <li>
                  Status
                  <Selec
                    id="customerStatus"
                    options={statusOptions}
                    defaultValue={statusOptions[user.customerStatus]}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        customerStatus: e.value,
                      });
                    }}
                  />
                </li>
                <li>
                  LastCheckinTime
                  <DTimePicker
                    name="lastCheckinTime"
                    value={
                      user.lastCheckinTime ? toDate(user.lastCheckinTime) : null
                    }
                    disableCalendar={false}
                    format="dd.MM.yyyy HH:mm:ss"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        lastCheckinTime: value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <h5 className="sectionTitle">Name and Address</h5>
            <hr className="underline" />

            <div>
              <ul>
                <li>
                  First Name
                  <Input
                    name="firstName"
                    type="text"
                    value={user.firstName}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        firstName: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  Last Name
                  <Input
                    name="lastName"
                    type="text"
                    value={user.lastName}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li style={{ width: "100px" }}>
                  Zip Code
                  <Input
                    name="zip"
                    type="text"
                    value={user.zip}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        zip: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  City
                  <Input
                    name="city"
                    type="text"
                    value={user.city}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        city: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  Street
                  <Input
                    name="street"
                    type="text"
                    value={user.street}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        street: e.target.value,
                      });
                    }}
                  />
                </li>
                <li style={{ width: "100px" }}>
                  HouseNumber
                  <Input
                    name="houseNumber"
                    type="text"
                    value={user.houseNumber}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        houseNumber: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <h5 className="sectionTitle">Contact</h5>
            <hr className="underline" />
            <div>
              <ul>
                <li>
                  Telephone
                  <Input
                    name="telPrivate"
                    type="text"
                    value={user.telPrivate}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        telPrivate: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  Mobile
                  <Input
                    name="telPrivateMobile"
                    type="text"
                    value={user.telPrivateMobile}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        telPrivateMobile: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  Email
                  <Input
                    name="email"
                    type="text"
                    value={user.email}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        email: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <h5 className="sectionTitle">Additional Data</h5>
            <hr className="underline" />
            <div>
              <ul>
                <li>
                  Gender
                  <Selec
                    id="gender"
                    options={genderOptions}
                    defaultValue={genderOptions[user.gender]}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        gender: e.value,
                      });
                    }}
                  />
                </li>

                <li>
                  BirthDay
                  <DPicker
                    className="dp"
                    dateFormat="dd.MM.yyyy"
                    name="dateOfBirth"
                    type="text"
                    selected={
                      user.dateOfBirth ? toDate(user.dateOfBirth) : null
                    }
                    value={user.dateOfBirth ? toDate(user.dateOfBirth) : null}
                    onChange={handleDateChange}
                  />
                </li>
                <li>
                  DNI/NIE/Passport
                  <Input
                    name="taxId"
                    type="text"
                    value={user.taxId}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        taxId: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>

            <h5 className="sectionTitle">Payment Data</h5>
            <hr className="underline" />
            <div>
              <ul>
                <li>
                  Iban
                  <Input
                    name="iban"
                    type="text"
                    value={user.iban}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        iban: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  BIC
                  <Input
                    name="bic"
                    type="text"
                    value={user.bic}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        bic: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  Account Holder
                  <Input
                    name="accountHolder"
                    type="text"
                    value={user.accountHolder}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        accountHolder: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  IsRecurringPay
                  <Selec
                    id="isRecurringPay"
                    options={isRecurringOptions}
                    defaultValue={isRecurringOptions[user.isRecurringPay]}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        isRecurringPay: e.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </DivForm>
        <Footer>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdKeyboardArrowLeft size={20} className="mdAdd" />
              BACK
            </button>
            <button
              className="defaultBtn"
              type="button"
              onClick={() => handleSave()}
            >
              <MdCheck size={20} className="mdAdd" />
              SAVE
            </button>
          </div>
        </Footer>
      </Form>
      {isSaveLoading && (
        <LoadingWraper>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#666666"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </LoadingWraper>
      )}
    </Container>
  );
};

MemberForm.propTypes = {
  closeModal: PropTypes.func,
  members: PropTypes.object,
};

export default MemberForm;
