import styled from "styled-components";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form as Unform } from "@unform/web";
import { darken } from "polished";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.divBtn {
    display: flex;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    color: #fff;
    margin: 0 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }
`;
export const Form = styled(Unform)`
  width: 100%;
`;
export const DivForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-radius: 4px;
  width: 100%;

  div {
    ul {
      display: flex;
      padding-left: 0px;
    }

    li {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      & + li {
        margin-left: 15px;
      }
    }

    input {
      margin: 10px 0;
      padding: 10px;
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 0.1px;
    }
    input.dp {
      width: 200px;
    }
  }

  select {
    margin-top: 10px;
    .select-selected:after {
      position: absolute;
      content: "";
      top: 14px;
      right: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #fff transparent transparent transparent;
    }
  }

  .cancelMembershipTitle {
    margin-right: 10px;
  }
  .cancelMembershipPicker {
    margin-left: 10px;
  }
`;

export const ASelect = styled(AsyncSelect)`
  padding: 10px 0;
  font-size: 16px;
  color: #333333;
  .css-1hwfws3 {
    height: 39px;
  }
`;

export const DPicker = styled(DatePicker)`
  width: 100% !important;
`;

export const Selec = styled(Select)`
  width: 100% !important;
  padding: 10px 0;
  font-size: 16px;
  height: 41px;
  min-width: 200px;

  .css-1hwfws3 {
    height: 39px;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 100px;
    color: #fff;
    margin: 5px 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }
  button.generateInvoiceBtn {
    width: 200px;
  }
`;
export const LoadingWraper = styled.div`
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
