import styled from "styled-components";
import { darken } from "polished";
import Select from "react-select";
export const Container = styled.div`
  padding: 0 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    font-size: 16px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 15px 0;
  width: 90%;
  button.defaultBtn {
    width: 140px;
  }

  .filterBar {
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
  }
  .react-datepicker-wrapper {
    height: 35px;
  }

  .custom-csv-button {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 140px;
    height: 35px;
    color: #fff;
    margin: 0 0px -5px 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, "#1c1c1c")};
    }
  }
`;

export const ContentWrapper = styled.ul`
  background: white;
  border-radius: 4px;
  padding: 30px 30px 10px 30px;
  width: 90%;
  .rdt_Table {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
  }

  .rdt_Pagination select {
    padding: 0px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 20px;
    background: #fff;
    color: #333333;
  }

  button.actionBtn {
    font-size: 15px;
    color: #4d85ee;
    width: 60px;
    font-weight: normal;
    background: #ffffff;
    border: 0;
    padding: 5px;

    &:hover {
      background: none;
      font-weight: bold;
    }
  }

  button.deleteBtn {
    width: 80px;
    color: #de3b3b;
  }

  .centerColumn {
    text-align: center;
  }
  .rightColumn {
    text-align: right;
  }
  .avatar {
    width: 40px;
    &:hover {
      transform: scale(4);
      z-index: 1;
      object-fit: cover;
    }
  }
`;
export const Input = styled.input`
  margin-left: 15px;
  height: 35px;
  width: 240px;
  margin-top: 4px;

  &::placeholder {
    font-size: 14px;
  }
`;

export const Selec = styled(Select)`
  padding: 5px 0;
  font-size: 16px;
  height: 35px;
  margin-top: -10px;
  margin-right: 15px;
  margin-left: 15px;
  .css-1hwfws3 {
    height: 39px;
  }
`;
