import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import { darken } from 'polished';

export const Container = styled.div`  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  div.divBtn {
    display: flex;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  width: 100%;

  button.defaultBtn {
    width: 100px;
    height: 30px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size:18px;
    font-weight: bold;
  }
`;
export const Form = styled(Unform)`  
  width: 100%;
`;
export const DivForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  width: 100%;
  
  .contentWrap {
    width:100%;
    padding-left: 5px;
  }




  strong {
    padding-top: 5px;
    font-size: 14px;
  }

  input {
    margin: 5px 0;
    padding: 10px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0.1px;

  }

  div {
    ul {
      display: flex;
      justify-content: left;
      padding-left: 0px !important;
    }

    li {
      display: flex;
      flex-direction: column;
      // flex-grow: 1;
      width: 32.5%;
      margin-right: 5px;
      margin-left: 5px;
    }
    
  }
`;
