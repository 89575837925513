import React, { useState, useEffect } from "react";
import { MdAdd, MdCheckCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { parseISO, format } from "date-fns";
import { toDate } from "date-fns-tz";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import ConfirmAlert from "../../components/ConfirmAlert";
import api from "../../services/api";
import { Container, Head, ContentWrapper, Input, Selec } from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatedFinacial, profileImageEndPoint } from "../../utils/utils";
const Bills = () => {
  const [bills, setBills] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [defaultTaxStatus, setDefaultTaxStatus] = useState("");
  const [defaultTaxRate, setDefaultTaxRate] = useState(0.0);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [filterStatus, setFilterStatus] = useState(1); //0: all, 1: unpaid, 2:paid
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    loadBillData();
  }, [filterStatus, startDate, endDate]);

  const loadInitialData = async () => {
    const taxRes = await api.get("settings/tax/default");
    const taxInfoRes = await api.get("settings/info/tax");
    const companyInfoRes = await api.get("settings/info/company");

    setDefaultTaxRate(taxRes.data.taxRate);
    setDefaultTaxStatus(taxInfoRes.data.info.taxStatus);
    setCompanyInfo(companyInfoRes.data.info);
    setIsLoading(false);
  };

  const loadBillData = async () => {
    setIsLoading(true);
    const response = await api.get("/bills", {
      params: {
        filter: filterStatus,
        startDate: startDate,
        endDate: endDate,
      },
    });
    setBills(response.data.bill);
    setData(response.data.bill);
    setIsLoading(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        "&:nth-child(1)": {
          justifyContent: "center",
        },
        "&:nth-child(3)": {
          justifyContent: "right",
        },
        "&:nth-child(4)": {
          justifyContent: "center",
        },
        "&:nth-child(6)": {
          justifyContent: "center",
        },
        "&:nth-child(7)": {
          justifyContent: "right",
        },
        "&:nth-child(8)": {
          justifyContent: "right",
        },
        "&:nth-child(9)": {
          justifyContent: "right",
        },
        "&:nth-child(10)": {
          justifyContent: "right",
        },
        "&:nth-child(11)": {
          justifyContent: "right",
        },
        "&:nth-child(12)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };
  const columns = [
    {
      name: "Avatar",
      width: "120px",
      style: { justifyContent: "center" },
      cell: (row) => (
        <img
          src={`${profileImageEndPoint()}/${row.user.customerNumber}`}
          className="avatar"
        />
      ),
      ignoreRowClick: true,
    },
    {
      width: "60px",
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => handleEditBill(row.id)}
          id={row.id}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      width: "100px",
      name: "Number",
      selector: (row) => row.invoiceNumber,
      style: { justifyContent: "center" },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const invoiceNumberA = parseInt(rowA.invoiceNumber);
        const invoiceNumberB = parseInt(rowB.invoiceNumber);
        return invoiceNumberA - invoiceNumberB;
      },
    },
    {
      name: "Date",
      selector: (row) => format(parseISO(row.currentDate), "dd.MM.yyyy"),
      style: { justifyContent: "center" },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.currentDate);
        const dateB = new Date(rowB.currentDate);
        return dateA - dateB;
      },
    },
    {
      // width: "250px",
      name: "Customer",
      selector: (row) => row.user.customerNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const customerNumberA = parseInt(
          rowA.user.customerNumber.replace("MG-", "")
        );
        const customerNumberB = parseInt(
          rowB.user.customerNumber.replace("MG-", "")
        );
        return customerNumberA - customerNumberB;
      },
    },
    {
      name: "Name",
      selector: (row) => row.billTo,
      sortable: true,
    },

    {
      name: "SubTotal",
      selector: (row) => formatedFinacial(row.subTotal) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Tax",
      selector: (row) => formatedFinacial(row.taxAmmount) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Discount",
      selector: (row) => formatedFinacial(row.discountAmmount) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "Total",
      selector: (row) => formatedFinacial(row.total) + row.currency,
      style: { justifyContent: "right" },
    },
    {
      name: "PaidDate",
      selector: (row) =>
        row.markDate ? format(parseISO(row.markDate), "dd.MM.yyyy") : "",
      style: { justifyContent: "right" },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.markDate);
        const dateB = new Date(rowB.markDate);
        return dateA - dateB;
      },
    },
    {
      name: "Status",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) =>
        row.status ? (
          <MdCheckCircle size={20} color="#42cb59" />
        ) : (
          <MdCheckCircle size={20} color="#dddddd" />
        ),
      ignoreRowClick: true,
    },

    {
      cell: (row) => (
        <button
          className="actionBtn deleteBtn"
          onClick={() =>
            ConfirmAlert("bills", row.id, (state) => {
              if (state) onRemoveBills(row.id);
            })
          }
          id={row.id}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      width: "80px",
    },
  ];

  const headers = [
    { label: "Number", key: "invoiceNumber" },
    { label: "Date", key: "currentDate" },
    { label: "Customer", key: "user.customerNumber" },
    { label: "SubTotal", key: "subTotal" },
    { label: "Tax", key: "taxAmmount" },
    { label: "Discount", key: "discountAmmount" },
    { label: "Total", key: "total" },
    { label: "PaidDate", key: "markDate" },
    { label: "Status", key: "status" },
  ];

  const filterOptions = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Unpaid",
    },
    {
      value: 2,
      label: "Paid",
    },
  ];

  const onRemoveBills = (id) => {
    setBills(bills.filter((t) => t.id !== id));
    setData(data.filter((t) => t.id !== id));
  };

  const handleEditBill = async (billId) => {
    try {
      const response = await api.get("bills/get-bill-by-id", {
        params: {
          id: billId,
        },
      });
      const info = response.data.bill;
      info.type = "edit";
      navigate("/invoiceform", { state: info });
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error.message
        : "Faile to get bill data!";
      toast.error(errMsg);
    }
  };

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(bills);
    } else {
      const newRows = bills.filter((row) => {
        if (
          row.billTo
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
          return row;
      });

      setData(newRows);
    }
  };

  const handleCreateBill = () => {
    navigate("/invoiceform", {
      state: {
        type: "add",
        defaultTaxRate: defaultTaxRate,
        defaultTaxStatus: defaultTaxStatus,
        companyInfo: companyInfo,
      },
    });
  };

  return (
    <Container>
      <Head>
        <h1>Bills</h1>
        <div className="filterBar">
          <DatePicker
            name="startDate"
            className="dp"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={startDate ? toDate(startDate) : null}
            onChange={(date) => {
              const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
              setStartDate(formattedDate);
            }}
            selectsStart
            startDate={startDate ? toDate(startDate) : null}
            endDate={endDate ? toDate(endDate) : null}
          />
          -
          <DatePicker
            name="endDate"
            className="dp"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={endDate ? toDate(endDate) : null}
            onChange={(date) => {
              const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
              setEndDate(formattedDate);
            }}
            selectsEnd
            startDate={startDate ? toDate(startDate) : null}
            endDate={endDate ? toDate(endDate) : null}
            minDate={startDate ? toDate(startDate) : null}
          />
          <Selec
            id="filter"
            options={filterOptions}
            defaultValue={filterOptions[filterStatus]}
            onChange={(e) => {
              setFilterStatus(e.value);
            }}
          />
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <button
            className="defaultBtn"
            type="button"
            onClick={() => handleCreateBill()}
          >
            <MdAdd size={20} className="mdAdd" />
            ADD
          </button>
          <CSVLink
            data={data}
            headers={headers}
            filename="data.csv"
            className="custom-csv-button"
          >
            Export to CSV
          </CSVLink>
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
    </Container>
  );
};

export default Bills;
