import { isAfter, isBefore, parseISO, parse, format } from "date-fns";
export const formatDate = (date) => {
  if (!date || date.length == 0) {
    return "";
  }
  let strArr = [];
  if (date.includes("-")) {
    strArr = date.split("-");
  } else if (date.includes("/")) {
    strArr = date.split("/");
  }

  return strArr[2] + "." + strArr[1] + "." + strArr[0];
};

export const formatDateTime = (dateString) => {
  // Parse the date string into a Date object
  const parsedDate = parse(dateString, "yyyyMMddHHmmss", new Date());

  // Format the Date object into the desired format
  const formattedDate = format(parsedDate, "dd.MM.yyyy HH:mm:ss");

  return formattedDate;
};

export const formatedFinacial = (data) => {
  const temp = data ? parseFloat(data).toFixed(2) : "0.00";
  return temp.replace(".", ",");
};

export const getEnrollmentState = (data) => {
  //0: deactive, 1: active
  if (!data || data.length == 0) {
    return 0;
  }
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (!element.remainNumberOfUsage) {
      if (
        isBefore(parseISO(element.startDate), new Date()) &&
        isAfter(parseISO(element.endDate), new Date())
      ) {
        return 1;
      }
    } else {
      if (
        isBefore(parseISO(element.startDate), new Date()) &&
        isAfter(parseISO(element.endDate), new Date()) &&
        element.remainNumberOfUsage > 0
      ) {
        return 1;
      }
    }
  }
  return 0;
};

export const getInvoiceState = (data) => {
  //0: no invoice, 1: open invoice, 2: all paid
  if (!data || data.length == 0) {
    return 0;
  }
  let paidCount = 0;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element.billNumber) paidCount++;
  }
  return data.length == paidCount ? 2 : 1;
};

export const activeMemberCount = (data) => {
  if (!data || data.length == 0) return 0;

  const activeEnrollments = data.filter((item) => item.active == true);
  return activeEnrollments.length;
};

export const profileImageEndPoint = () => {
  return "https://fitwork.webconnect.pro/api/users/profile-img";
};
