import styled from "styled-components";
import { darken } from "polished";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  text-align: center;
  background: #fff;
  padding: 50px 30px 50px 30px;
  border-radius: 4px;

  div {
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    strong {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #444444;
      text-align: left;
      padding: 5px 0;
    }

    button {
      background: #1c1c1c;
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #fff;
      margin: 15px 0 0;
      font-weight: bold;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.03, "#1c1c1c")};
      }
    }
  }
`;
