import React, { useState, useEffect } from "react";
import { MdAdd, MdCheckCircle } from "react-icons/md";

import ConfirmAlert from "../../components/ConfirmAlert";
import api from "../../services/api";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { Container, Head, ContentWrapper, Input } from "./styles";
import MembershipForm from "../MembershipForm";
import CustomModal from "../../components/Modal/CustomModal";
import { formatedFinacial, activeMemberCount } from "../../utils/utils";
const Memberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [defaultTaxStatus, setDefaultTaxStatus] = useState("");
  const [defaultTaxRate, setDefaultTaxRate] = useState(null);

  useEffect(() => {
    async function loadMemberships() {
      const response = await api.get("memberships");
      const taxRes = await api.get("settings/tax/default");
      const taxInfoRes = await api.get("settings/info/tax");

      setMemberships(response.data.memberships);
      setData(response.data.memberships);
      setDefaultTaxRate(taxRes.data.taxRate);
      setDefaultTaxStatus(taxInfoRes.data.info.taxStatus);

      setIsLoading(false);
    }
    loadMemberships();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        "&:nth-child(2)": {
          justifyContent: "center",
        },
        "&:nth-child(3)": {
          justifyContent: "right",
        },
        "&:nth-child(4)": {
          justifyContent: "right",
        },
        "&:nth-child(5)": {
          justifyContent: "right",
        },
        "&:nth-child(6)": {
          justifyContent: "right",
        },
        "&:nth-child(7)": {
          justifyContent: "center",
        },
        "&:nth-child(8)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };

  const columns = [
    {
      width: "300px",
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Duration",
      selector: (row) =>
        row.duration +
        ` ${
          row.durationType
            ? row.duration == 1
              ? "day"
              : "days"
            : row.duration == 1
            ? "month"
            : "months"
        }`,
      style: { justifyContent: "center" },
    },
    {
      name: "Price",
      style: { justifyContent: "right" },
      selector: (row) => formatedFinacial(row.price) + `€`,
    },
    {
      name: "Tax Status",
      style: { justifyContent: "right" },
      selector: (row) => row.taxStatus,
    },
    {
      name: "Tax Amount",
      style: { justifyContent: "right" },
      selector: (row) =>
        formatedFinacial((row.price / 100) * row.taxRate) + "€",
    },
    {
      name: "Total Price",
      style: { justifyContent: "right" },
      selector: (row) => formatedFinacial(row.totalPrice) + "€",
    },
    {
      name: "Active Number",
      style: { justifyContent: "right" },
      selector: (row) => activeMemberCount(row.enrollments),
    },
    {
      name: "Status",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) =>
        row.isActive ? (
          <MdCheckCircle size={20} color="#42cb59" />
        ) : (
          <MdCheckCircle size={20} color="#dddddd" />
        ),
      ignoreRowClick: true,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return rowA.isActive - rowB.isActive;
      },
    },
    {
      width: "80px",
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => handleEditMembership(row)}
          id={row.id}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      style: { justifyContent: "right" },
      cell: (row) => (
        <button
          className="actionBtn deleteBtn"
          onClick={() =>
            ConfirmAlert("memberships", row.id, (state) => {
              if (state) onRemoveMembership(row.id);
            })
          }
          id={row.id}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      width: "100px",
    },
  ];

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(memberships);
    } else {
      const newRows = memberships.filter((row) => {
        if (
          row.title
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
          return row;
      });

      setData(newRows);
    }
  };
  const handleEditMembership = (membership) => {
    setItem(membership);
    setIsOpen(true);
  };

  const handleAddMembership = () => {
    setItem(null);
    setIsOpen(true);
  };

  const onRemoveMembership = (id) => {
    setMemberships(memberships.filter((t) => t.id !== id));
    setData(data.filter((t) => t.id !== id));
  };

  return (
    <Container>
      <Head>
        <h1>Membership List</h1>
        <div>
          <button
            className="defaultBtn"
            type="button"
            onClick={() => handleAddMembership()}
          >
            <MdAdd size={20} className="mdAdd" />
            Add
          </button>
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
      <CustomModal isOpen={isOpen}>
        <MembershipForm
          closeModal={() => setIsOpen(false)}
          membership={item}
          defaultTaxRate={defaultTaxRate}
          defaultTaxStatus={defaultTaxStatus}
        />
      </CustomModal>
    </Container>
  );
};

export default Memberships;
