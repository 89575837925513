import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import PropTypes from "prop-types";
import { format, addDays, addMonths } from "date-fns";
import { toDate } from "date-fns-tz";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditableField from "./EditableField";
import CreatableSelect from "react-select/creatable";
import api from "../../services/api";
import "./CustomDatepicker.css";
const InvoiceItem = ({
  onTaxInfoChanged,
  onItemizedItemEdit,
  currency,
  onRowAdd,
  onRowDel,
  items,
  disabled,
}) => {
  const [memberships, setMemberships] = useState([]);
  useEffect(() => {
    async function loadMemberships() {
      const membershipRes = await api.get("memberships");
      setMemberships(membershipRes.data.memberships);
    }
    loadMemberships();
  }, []);
  const itemTable = items.map((item) => {
    return (
      <ItemRow
        onTaxInfoChanged={(rate, status) => onTaxInfoChanged(rate, status)}
        onItemizedItemEdit={(e) => onItemizedItemEdit(e)}
        item={item}
        onDelEvent={() => onRowDel(item)}
        key={item.id}
        currency={currency}
        memberships={memberships}
        disabled={disabled}
      />
    );
  });
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Start</th>
            <th>End</th>
            <th>Qty</th>
            <th>Price/Rate</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>{itemTable}</tbody>
      </Table>
      <Button className="fw-bold" onClick={onRowAdd}>
        Add Item
      </Button>
    </div>
  );
};

InvoiceItem.propTypes = {
  onTaxInfoChanged: PropTypes.func.isRequired,
  onItemizedItemEdit: PropTypes.func.isRequired,
  onRowAdd: PropTypes.func.isRequired,
  onRowDel: PropTypes.func.isRequired,
  items: PropTypes.array,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
};

const ItemRow = ({
  onTaxInfoChanged,
  onItemizedItemEdit,
  onDelEvent,
  item,
  currency,
  memberships,
  disabled,
}) => {
  const [price, setPrice] = useState(item.price);
  // const [name, setName] = useState(item.name);
  const [startDate, setStartDate] = useState(item.startDate);
  const [endDate, setEndDate] = useState(item.endDate);

  useEffect(() => {
    const editValue = {
      id: item.id,
      name: "price",
      value: price,
    };
    onItemizedItemEdit(editValue);
  }, [price]);

  const options = memberships.map((item) => {
    return {
      value: item.id,
      label: item.title,
    };
  });

  const handleDateChange = (date) => {
    const editValue = {
      id: item.id,
      name: "startDate",
      value: date ? date : null,
    };
    onItemizedItemEdit(editValue);
  };
  const handleChange = (evt) => {
    const editValue = {
      id: evt.id,
      name: evt.name,
      value: evt.value ? evt.value.label : "",
    };

    if (evt.value) {
      const selectedMembership = memberships.filter(
        (item) => item.title == evt.value.label
      );

      if (selectedMembership && selectedMembership.length > 0) {
        setPrice(
          selectedMembership.length > 0
            ? selectedMembership[0].price
            : item.price
        );

        const tmpDate =
          selectedMembership[0].durationType == 0
            ? addMonths(
                toDate(startDate),
                parseInt(selectedMembership[0].duration)
              )
            : addDays(
                toDate(startDate),
                parseInt(selectedMembership[0].duration)
              );
        setEndDate(tmpDate);
        if (evt.name == "name") {
          onTaxInfoChanged(
            selectedMembership[0].taxRate,
            selectedMembership[0].taxStatus
          );
        }
      }
    } else {
      setPrice(1);
    }

    onItemizedItemEdit(editValue);
  };
  const handleInputChange = (evt) => {};
  return (
    <tr>
      <td style={{ width: "100%" }}>
        <CreatableSelect
          id={item.id}
          name="name"
          // isClearable
          onChange={(e) =>
            handleChange({
              id: item.id,
              name: "name",
              value: e,
            })
          }
          onInputChange={(e) => handleInputChange(e)}
          options={options}
          placeholder="Item name"
          className="my-1"
          defaultValue={{
            value: item.id,
            label: item.name,
          }}
          isDisabled={disabled}
        />
      </td>
      <td>
        <div className="custom-datepicker">
          <DatePicker
            name="dateOfIssue"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={startDate ? toDate(startDate) : null}
            onChange={(date) => handleDateChange(date)}
            value={startDate ? toDate(startDate) : null}
            disabled={true}
          />
        </div>
      </td>
      <td>
        <div className="custom-datepicker">
          <DatePicker
            name="dateOfIssue"
            placeholderText="dd.mm.yyyy"
            dateFormat="dd.MM.yyyy"
            selected={endDate ? toDate(endDate) : null}
            onChange={(date) => {
              const formattedDate = format(date, "yyyy-MM-dd");
              // setBillInfo({
              //   ...billInfo,
              //   dateOfIssue: formattedDate,
              // });
            }}
            value={endDate ? toDate(endDate) : null}
            disabled={true}
          />
        </div>
      </td>
      <td style={{ minWidth: "80px" }}>
        <EditableField
          onItemizedItemEdit={(e) =>
            onItemizedItemEdit({
              id: e.target.id,
              name: e.target.name,
              value: e.target.value,
            })
          }
          cellData={{
            type: "number",
            name: "qty",
            min: 1,
            step: "1",
            value: item.qty,
            id: item.id,
            disabled: disabled,
          }}
        />
      </td>
      <td style={{ minWidth: "130px" }}>
        <EditableField
          onItemizedItemEdit={(e) => {
            onItemizedItemEdit({
              id: e.target.id,
              name: e.target.name,
              value: e.target.value,
            });
            setPrice(e.target.value);
          }}
          cellData={{
            leading: currency,
            type: "number",
            name: "price",
            min: 1,
            step: "0.01",
            presicion: 2,
            value: price,
            id: item.id,
            disabled: disabled,
          }}
        />
      </td>
      <td className="text-center" style={{ minWidth: "50px" }}>
        <BiTrash
          onClick={onDelEvent}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white mt-1 btn btn-danger"
        />
      </td>
    </tr>
  );
};

ItemRow.propTypes = {
  onTaxInfoChanged: PropTypes.func.isRequired,
  onItemizedItemEdit: PropTypes.func.isRequired,
  onDelEvent: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  currency: PropTypes.string,
  memberships: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default InvoiceItem;
