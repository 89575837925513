import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import { darken } from 'polished';

export const Container = styled.div`  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  div.divBtn {
    display: flex;
  }
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  width: 100%;

  button.defaultBtn {
    width: 100px;
    height: 30px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size:18px;
    font-weight: bold;
  }
`;
export const Form = styled(Unform)`  
  width: 100%;
`;
export const DivForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  width: 100%;
  
  .contentWrap {
    width:100%;
  }

  .taxRateTitle, .taxCalcTitle {
    font-size:16px;
    padding: 10px;
  }

  .radioWrapper {
    padding: 10px;
  }


  strong {
    padding-top: 5px;
    font-size: 14px;
  }

  input {
    margin: 5px 0;
    padding: 10px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0.1px;

  }

  div {
    ul {
      display: flex;
      justify-content: space-between;
      padding-left: 5px !important;
    }

    li {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-right: 5px;
      margin-left: 5px;
    }
    
  }
`;
export const RateTableWrapper = styled.div`
  background: white;
  border-radius: 4px;
  padding: 10px 10px;
  width: 100%;

  .rdt_Table {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30vh;
    overflow-y: auto;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.87);
    background-color: #FFFFFF;
  }

  .rdt_Pagination select {
    padding: 0px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 20px;
    background: #fff;
    color: #333333;
  }

  button.actionBtn {
    font-size: 15px;
    color: #4d85ee;
    width: 45px;
    font-weight: normal;
    background: #ffffff;
    border: 0;
    padding: 5px;

    &:hover {
      background: none;
      font-weight: bold;
    }
  }

  button.defaultBtn {
    width: 100px;
  }

  button.deleteBtn {
    width: 65px;
    color: #de3b3b;
  }

  .centerColumn {
    text-align: center;
  }
  .rightColumn {
    text-align: right;
  }
`;