import React, { useState, useEffect, useRef } from "react";
import { Container, Head, DivForm, Form, RateTableWrapper } from "./styles";
// import Radio from "../../../components/Form/Radio";
import { MdAdd, MdCheckCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import ConfirmAlert from "../../../components/ConfirmAlert";
import CustomModal from "../../../components/Modal/CustomModal";
import TaxForm from "../TaxForm";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Tax = () => {
  const [rateList, setRateList] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [taxStatus, setTaxStatus] = useState("");

  const formRef = useRef(null);
  useEffect(() => {
    async function loadTaxSettings() {
      const taxInfoRes = await api.get("settings/info/tax");

      setTaxStatus(taxInfoRes.data.info.taxStatus);
      const taxRes = await api.get("settings/tax");
      setRateList(taxRes.data.taxRates);
      setIsLoading(false);
    }
    loadTaxSettings();
  }, []);

  const handleSave = () => {};

  const options = [
    { id: "exclude", value: "exclude", label: "Excluding Tax" },
    { id: "include", value: "include", label: "Including Tax" },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };

  const columns = [
    {
      name: "Tax Rate",
      selector: (row) => row.taxRate,
      sortable: true,
    },
    {
      name: "Tax Name",
      selector: (row) => row.taxName,
      sortable: true,
    },
    {
      name: "Is Default",
      cell: (row) =>
        row.isDefault ? (
          <MdCheckCircle size={20} color="#42cb59" />
        ) : (
          <MdCheckCircle size={20} color="#dddddd" />
        ),
      ignoreRowClick: true,
    },
    {
      width: "60px",
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => handleEditRate(row)}
          id={row.id}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      cell: (row) => (
        <button
          className="actionBtn deleteBtn"
          onClick={() =>
            ConfirmAlert("settings/tax", row.id, (state) => {
              if (state) onRemoveRate(row.id);
            })
          }
          id={row.id}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      width: "80px",
    },
  ];

  const handleEditRate = (rate) => {
    setItem(rate);
    setIsOpen(true);
  };

  const handleAddRate = () => {
    setItem(null);
    setIsOpen(true);
  };

  const onRemoveRate = (id) => {
    setRateList(rateList.filter((t) => t.id !== id));
  };

  // // Custom Radio component for Unform
  const getRadioComponent = (name) => {
    return (
      <div>
        {options &&
          options.map((option) => (
            <label key={option.id}>
              <input
                type="radio"
                name={name}
                value={option.value}
                checked={taxStatus == option.value}
                onChange={handleTaxStatusChange}
              />
              {option.label}
            </label>
          ))}
      </div>
    );
  };

const handleTaxStatusChange = async(e) => {
  try{
    const taxInfoRes = await api.post("settings/info/tax",{
      taxStatus: e.target.value
    });
    setTaxStatus(e.target.value);
    toast.success("Tax status changed successfully!");
  }catch(err){
    toast.error("Something went wrong!");
  }
  
}

  return (
    <Container>
      <Form
        ref={formRef}
        /* schema={} */ onSubmit={handleSave}
        encType={"multipart/form-data"}
        // initialData={{taxStatus : taxStatus}}
      >
        <Head>
          <div>Tax</div>
          <div>
            <button
              className="defaultBtn"
              type="button"
              onClick={() => handleAddRate()}
            >
              <MdAdd size={20} className="mdAdd" />
              ADD
            </button>
          </div>
        </Head>
        <hr className="my-3" />
        <DivForm>
          <div className="contentWrap">
            {/* <div className="taxRateTitle">
              <strong>Tax Rate</strong>
            </div> */}
            <div>
              <RateTableWrapper>
                <DataTable
                  columns={columns}
                  data={rateList}
                  pagination
                  paginationPerPage={30}
                  paginationRowsPerPageOptions={[30, 40, 50]}
                  progressPending={isLoading}
                  progressComponent={
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#666666"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  }
                  customStyles={customStyles}
                />

                <CustomModal isOpen={isOpen}>
                  <TaxForm closeModal={() => setIsOpen(false)} tax={item} />
                </CustomModal>
              </RateTableWrapper>
            </div>

            <div className="taxCalcTitle">
              <strong>Standard Tax Calculation</strong>
            </div>
            <div className="radioWrapper">
              {getRadioComponent("taxStatus")}
            </div>
          </div>
        </DivForm>
      </Form>
    </Container>
  );
};

export default Tax;
