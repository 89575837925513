import React, { useState, useEffect } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import api from "../../services/api";
import { Container, Head, ContentWrapper, Input } from "./styles";
import { formatDateTime } from "../../utils/utils";
import { parse, format } from "date-fns";
const EntranceHistory = () => {
  const [entranceHistory, setEntranceHistory] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    async function loadEntranceHistory() {
      const response = await api.get("/entrance-history");
      console.log(response);
      setEntranceHistory(response.data.history);
      setData(response.data.history);
      setIsLoading(false);
    }
    loadEntranceHistory();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        justifyContent: "center",
        "&:nth-child(5)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
        justifyContent: "center",
      },
    },
  };
  const columns = [
    {
      name: "Avatar",
      width: "160px",
      style: { justifyContent: "center" },
      cell: (row) => (
        <img src={`data:image/png;base64,${row.logPhoto}`} className="avatar" />
      ),
      ignoreRowClick: true,
    },

    {
      width: "100px",
      name: "ID",
      selector: (row) => row.userId,
    },
    {
      name: "Time",
      selector: (row) => formatDateTime(row.time),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = parse(rowA.time, "yyyyMMddHHmmss", new Date());
        const dateB = parse(rowB.time, "yyyyMMddHHmmss", new Date());
        return dateA - dateB;
      },
    },

    {
      name: "Verify Mode",
      selector: (row) => row.verifyMode,
    },
    {
      name: "InOut",
      selector: (row) => row.inOut,
    },
    {
      name: "doorMode",
      selector: (row) => row.doorMode,
    },
    // {
    //   name: "Status",
    //   width: "120px",
    //   style: { justifyContent: 'center' },
    //   cell: (row) => (
    //     row.status ? (
    //       <span><MdCheckCircle size={20} color="#42cb59" /></span>
    //     ) : (
    //       <span><MdCancel size={20} color="#ff0000" /></span>
    //     )
    //   ),
    //   ignoreRowClick: true,
    // },
  ];

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(entranceHistory);
    } else {
      const newRows = entranceHistory.filter((row) => {
        if (
          row.userId
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
          return row;
      });

      setData(newRows);
    }
  };

  return (
    <Container>
      <Head>
        <h1>Entrance History</h1>
        <div>
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
    </Container>
  );
};

export default EntranceHistory;
