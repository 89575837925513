import React, { useState, useEffect } from "react";
import { Container, Head, DivForm, Form } from "./styles";
import Input from "../../../components/Form/Input";
import { MdCheck } from "react-icons/md";
import api from "../../../services/api";
import { toast } from "react-toastify";

const Company = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [companyInfo, setCompanyInfo] = useState({
    companyName:'',
    email:'',
    zip:'',
    city:'',
    street:'',
    houseNumber:'',
    telephone:'',
    taxId:'',
    bankName:'',
    iban: '',
    bic: ''
  });
  

  useEffect(() => {
    async function loadCompanySettings() {
      const response = await api.get("settings/info/company");
      setCompanyInfo(response.data.info);
      setIsLoading(false);
    }
    loadCompanySettings();
  }, [])
  
  const handleSave = async() => {
    try{
      const response = await api.post("settings/info/company", companyInfo);
      toast.success("Company detaill edited successfully!");
    }catch(err){
      toast.error("Something went wrong!");
    }
    
  };
  return (
    <Container>
      <Form
        /* schema={} */ onSubmit={handleSave}
        encType={"multipart/form-data"}
      >
      <Head>
        <div>Company Detail</div>
        <div >          
            <button className="defaultBtn" type="submit">
              <MdCheck size={20} className="mdAdd" />
              SAVE
            </button>
          </div>
      </Head>
      <hr className="my-3" />
        <DivForm>
          <div className="contentWrap">
            <div>
              <ul>
                <li>
                  <strong>Company Name</strong>
                  <Input
                    name="companyName"
                    type="text"
                    value={companyInfo.companyName}
                    onChange={(e) => {
                        setCompanyInfo({
                        ...companyInfo,
                        companyName: e.target.value,
                      });
                    }}
                  />
                </li>
                
                <li>
                  <strong>Email</strong>
                  <Input
                    name="email"
                    type="text"
                    value={companyInfo.email}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        email: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Zip Code</strong>
                  <Input
                    name="zip"
                    type="text"
                    value={companyInfo.zip}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        zip: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>         
          

            <div>
              <ul>               
                <li>
                  <strong>City</strong>
                  <Input
                    name="city"
                    type="text"
                    value={companyInfo.city}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        city: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>Street</strong>
                  <Input
                    name="street"
                    type="text"
                    value={companyInfo.street}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        street: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>House Number</strong>
                  <Input
                    name="houseNumber"
                    type="text"
                    value={companyInfo.houseNumber}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        houseNumber: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <div>
              <ul>
              <li>
                  <strong>Bank Name</strong>
                  <Input
                    name="bankName"
                    type="text"
                    value={companyInfo.bankName}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        bankName: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>IBAN</strong>
                  <Input
                    name="iban"
                    type="text"
                    value={companyInfo.iban}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        iban: e.target.value,
                      });
                    }}
                  />
                </li>
                <li>
                  <strong>BIC</strong>
                  <Input
                    name="bic"
                    type="text"
                    value={companyInfo.bic}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        bic: e.target.value,
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <strong>Telephone</strong>
                  <Input
                    name="telephone"
                    type="text"
                    value={companyInfo.telephone}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        telephone: e.target.value,
                      });
                    }}
                  />
                </li>
                

                <li>
                  <strong>Tax ID</strong>
                  <Input
                    name="taxId"
                    type="text"
                    value={companyInfo.taxId}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        taxId: e.target.value,
                      });
                    }}
                  />
                </li>                
              </ul>
            </div>
            
           
          </div>
        </DivForm>
       
      </Form>
    </Container>
  );
};

export default Company;
