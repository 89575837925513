import styled from 'styled-components';
import { darken } from 'polished';
import { Form as Unform } from '@unform/web';
import Select from 'react-select';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.divBtn {
    display: flex;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    color: #fff;
    margin: 0 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, '#1c1c1c')};
    }
  }
`;
export const Form = styled(Unform)`  
  width: 100%;
`;

export const DivForm = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-radius: 4px;
  width: 100%;

  input {
    margin: 10px 0;
    padding: 10px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0.1px;

  }

  div {
    ul {
      display: flex;
      justify-content: space-between;
      padding-left: 0px;
    }

    li {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      & + li {
        margin-left: 15px;
      }
    }
    .noRecurringTitle {
      margin-right:10px;
    }
    .numberOfUsage {
      margin-left: 10px
    }
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  button.defaultBtn {
    background: #1c1c1c;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
    width: 100px;
    color: #fff;
    margin: 5px 0px 0 10px;
    font-weight: bold;
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, '#1c1c1c')};
    }
  }
`;
export const Selec = styled(Select)`
  width: 100% !important;
  padding: 5px 0;

  margin-top:5px;
  font-size: 16px;
  height: 41px;
  min-width: 200px;

  .css-1hwfws3 {
    height: 39px;
  }
`;