export function signInRequest(email, password, isMobile) {
  return {
    type: "@auth/SIGN_IN_REQUEST",
    payload: { email, password, isMobile },
  };
}

export function signInSuccess(token, user) {
  return {
    type: "@auth/SIGN_IN_SUCCESS",
    payload: { token, user },
  };
}

export function signInFailure() {
  return {
    type: "@auth/SIGN_IN_FAILURE",
  };
}

export function signOut() {
  return {
    type: "@auth/SIGN_OUT",
  };
}
