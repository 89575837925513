import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SignIn from "../pages/SignIn";
import Members from "../pages/Members";
import Memberships from "../pages/Memberships";
import MemberManagement from "../pages/MemberManagement";
import Mobile from "../pages/Mobile";
import Bills from "../pages/Bills";
import Payments from "../pages/Payments";
import Settings from "../pages/Settings";
import Company from "../pages/Settings/Company";
import Payment from "../pages/Settings/Payment";
import Tax from "../pages/Settings/Tax";
import Security from "../pages/Settings/Security";

import NormalLayout from "../components/Layout/NormalLayout";
import ProtectedLayout from "../components/Layout/ProtectedLayout";
import InvoiceForm from "../pages/InvoiceForm";
import EntranceHistory from "../pages/EntranceHistory";
export default function RoutesWapper() {
  return (
    <Routes>
      <Route element={<NormalLayout />}>
        <Route path="/" exact element={<SignIn />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        <Route path="/members" element={<Members />} />

        <Route path="/memberships" element={<Memberships />} />
        <Route path="/membermanagement" element={<MemberManagement />} />
        <Route path="/bills" element={<Bills />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/history" element={<EntranceHistory />} />
        {/* <Route path="/questions" element={<Questions />} /> */}
        <Route path="/invoiceform" element={<InvoiceForm />} />
        <Route path="settings" element={<Settings />}>
          <Route index element={<Navigate to="company" />} />
          <Route path="company" element={<Company />} />
          <Route path="payment" element={<Payment />} />
          <Route path="tax" element={<Tax />} />
          <Route path="security" element={<Security />} />
        </Route>
      </Route>
      <Route path="/mobile" element={<Mobile />} />
    </Routes>
  );
}
