import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiCheck } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import api from "../../services/api";

const MarkBillModal = ({ showModal, closeModal, info }) => {
  const [billNumber, setBillNumber] = useState(`b_${info.invoiceNumber}`);
  const [paidDate, setPaidDate] = useState(info.currentDate);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const markBill = async (id) => {
    setIsSaveLoading(true);
    try {
      if (billNumber.length > 0) {
        const res = await api.put(`bills/${id}/mark-bill`, {
          id: id,
          billNumber: billNumber,
          markDate: paidDate,
        });
        const bill = res.data.data;

        const transactionData = {
          paymentId: "",
          currency: bill.currency,
          amount: bill.total,
          description: "",
          status: 1,
          paymentMethod: paymentMethod,
          customer: bill.billTo,
          billNumber: `b_${bill.invoiceNumber}`,
          paidDate: paidDate,
          userId: bill.toUserId,
        };
        await api.post("transactions", transactionData);

        //face device update
        const faceDeviceResponse = await api.post(`update-face-device`, {
          id: bill.toUserId,
        });

        window.location.href = "/bills";

        toast.success("Success to mark bill!");
      } else {
        toast.error("Empty bill number");
      }
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error
        : "Something went wrong!";
      toast.error(errMsg);
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleChangeBillNumber = (billNumber) => {
    if (billNumber.length > 0) {
      setBillNumber(billNumber);
    }
  };
  const paymentOptions = [
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "banktransfer",
      label: "Bank Transfer",
    },
    {
      value: "direct debt",
      label: "Direct debt",
    },
    {
      value: "credit card",
      label: "Credit Card",
    },
    {
      value: "stripe",
      label: "Stripe",
    },
  ];
  return (
    <div>
      <Modal show={showModal} size="md" centered>
        <div id="invoiceCapture">
          <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-3">
            <div className="w-100">
              <h6 className="fw-bold my-2">Marked the Bill</h6>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <Col
                md={3}
                className="d-flex flex-row justify-content-center align-items-center"
              >
                <Form.Label className="fw-bold d-flex flex-row justify-content-center align-items-center">
                  Date:
                </Form.Label>
              </Col>
              <Col md={9}>
                <DatePicker
                  name="dateOfIssue"
                  className="form-control"
                  placeholderText="dd.mm.yyyy"
                  dateFormat="dd.MM.yyyy"
                  selected={paidDate ? toDate(paidDate) : null}
                  onChange={(date) => {
                    const formattedDate = format(date, "yyyy-MM-dd");
                    setPaidDate(formattedDate);
                  }}
                  value={paidDate ? toDate(paidDate) : null}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                md={3}
                className="d-flex flex-row justify-content-center align-items-center"
              >
                <Form.Label className="fw-bold d-flex flex-row justify-content-center align-items-center">
                  Bill Number:
                </Form.Label>
              </Col>
              <Col md={9}>
                <Form.Control
                  placeholder={"Input bill number"}
                  rows={1}
                  type="text"
                  name="billNumber"
                  defaultValue={billNumber}
                  onChange={(e) =>
                    handleChangeBillNumber(e.target.value.trim())
                  }
                  autoComplete="name"
                  required="required"
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                md={3}
                className="d-flex flex-row justify-content-center align-items-center"
              >
                <Form.Label className="fw-bold d-flex flex-row justify-content-center align-items-center">
                  Payment:
                </Form.Label>
              </Col>
              <Col md={9}>
                <Select
                  id="paymentMethod"
                  // className="form-control"
                  options={paymentOptions}
                  // defaultValue={handleMemberValue()}
                  onChange={(e) => {
                    setPaymentMethod(e.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        {isSaveLoading && (
          <div className="d-flex inset-0 justify-content-center align-items-center w-full h-full">
            <ThreeDots
              visible={true}
              height="40"
              width="40"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ marginBottom: "30px" }}
              wrapperClass=""
            />
          </div>
        )}
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button
                variant="primary"
                className="d-block w-100"
                onClick={() => markBill(info.id)}
                disabled={isSaveLoading}
              >
                <BiCheck
                  style={{ width: "15px", height: "15px", marginTop: "-3px" }}
                  className="me-2"
                />
                Mark Bill
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant="outline-primary"
                className="d-block w-100 mt-3 mt-md-0"
                onClick={closeModal}
                disabled={isSaveLoading}
              >
                <MdClose
                  style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                  className="me-2"
                />
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

MarkBillModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object,
};

export default MarkBillModal;
