import React, { useState, useEffect } from "react";
import { MdAdd, MdCheckCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import {
  formatDate,
  getEnrollmentState,
  getInvoiceState,
  profileImageEndPoint,
} from "../../utils/utils";
import ConfirmAlert from "../../components/ConfirmAlert";

import api from "../../services/api";

import { Container, Head, ContentWrapper, Input } from "./styles";

import MemberForm from "../MemberForm";
import CustomModal from "../../components/Modal/CustomModal";
import BillListModal from "../../components/Invoice/BillListModal";

const Members = () => {
  const [members, setMembers] = useState([]);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [item, setItem] = useState(null);
  const [fileterStatus, setFileterStatus] = useState(true);
  const [isOpenBillModal, setIsOpenBillModal] = useState(false);
  const [billList, setBillList] = useState([]);

  useEffect(() => {
    async function loadMembers() {
      const response = await api.get("users");
      setMembers(response.data.users);
      const activeMembers = response.data.users.filter((row) => {
        if (getEnrollmentState(row.enrollments) == 1) return row;
      });
      setData(activeMembers);
      setIsLoading(false);
    }
    loadMembers();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#444444",
        fontWeight: "bold",
        paddingLeft: "5px",
        paddingRight: "5px",
        "&:nth-child(1)": {
          justifyContent: "center",
        },
        "&:nth-child(3)": {
          justifyContent: "center",
        },
        "&:nth-child(9)": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "15px",
        color: "#666666",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
  };

  const columns = [
    {
      name: "Avatar",
      width: "120px",
      style: { justifyContent: "center" },
      cell: (row) => (
        <img
          className="avatar"
          src={`${profileImageEndPoint()}/${row.customerNumber}`}
        />
      ),
      ignoreRowClick: true,
    },
    {
      width: "100px",
      name: "Number",
      selector: (row) => row.customerNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const customerNumberA = parseInt(
          rowA.customerNumber.replace("MG-", "")
        );
        const customerNumberB = parseInt(
          rowB.customerNumber.replace("MG-", "")
        );
        return customerNumberA - customerNumberB;
      },
    },
    {
      name: "Invoice Status",
      // width: "80px",
      style: { justifyContent: "center" },
      cell: (row) => {
        const state = getInvoiceState(row.bills);
        const color =
          state == 0 ? "#dddddd" : state == 1 ? "#ff0000" : "#42cb59";
        return (
          <button className="invoiceStatusBtn">
            <MdCheckCircle
              size={20}
              color={color}
              onClick={() => showInvoiceModal(row.bills)}
            />
          </button>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const stateA = getInvoiceState(rowA.bills);
        const stateB = getInvoiceState(rowB.bills);
        return stateA - stateB;
      },
      ignoreRowClick: true,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      width: "250px",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.telPrivateMobile,
      sortable: true,
    },
    {
      name: "Birthday",
      selector: (row) => formatDate(row.dateOfBirth),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.dateOfBirth);
        const dateB = new Date(rowB.dateOfBirth);
        return dateA - dateB;
      },
    },
    {
      name: "Status",
      width: "80px",
      style: { justifyContent: "center" },
      cell: (row) => {
        const color = getEnrollmentState(row.enrollments)
          ? "#42cb59"
          : "#dddddd";
        return <MdCheckCircle size={20} color={color} />;
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const stateA = getEnrollmentState(rowA.enrollments);
        const stateB = getEnrollmentState(rowB.enrollments);
        return stateA - stateB;
      },
      ignoreRowClick: true,
    },
    {
      width: "60px",
      cell: (row) => (
        <button
          className="actionBtn"
          onClick={() => handleEditMember(row.id)}
          id={row.id}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
    },
    {
      cell: (row) => (
        <button
          className="actionBtn deleteBtn"
          onClick={() =>
            ConfirmAlert("users", row.id, (state) => {
              if (state) onRemoveMembers(row.id);
            })
          }
          id={row.id}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      width: "80px",
    },
  ];

  const handleEditMember = async (id) => {
    try {
      const response = await api.get(`users/${id}`);
      setItem(response.data.data);
      setIsOpen(true);
    } catch (err) {
      const errMsg = err.response
        ? err.response.data.error.message
        : "Faile to get member data!";
      toast.error(errMsg);
    }
  };

  const handleAddMember = () => {
    setItem(null);
    setIsOpen(true);
  };

  const handleSearchChange = async (event) => {
    if (event.target.value.length == 0) {
      setData(members);
    } else {
      const newRows = members.filter((row) => {
        if (
          row.customerNumber
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          row.firstName
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          row.lastName
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          (row.eamil && row.email
            .toString()
            .toLowerCase()
            .includes(event.target.value.toLowerCase()))
        )
          return row;
      });

      setData(newRows);
    }
  };
  const handleFilter = () => {
    if (!fileterStatus) {
      const newRows = members.filter((row) => {
        if (getEnrollmentState(row.enrollments) == 1) return row;
      });

      setData(newRows);
      setFileterStatus(true);
    } else {
      setFileterStatus(false);
      setData(members);
    }
  };
  const onRemoveMembers = (id) => {
    setMembers(members.filter((t) => t.id !== id));
    setData(data.filter((t) => t.id !== id));
  };

  const closeBillModal = () => {
    setIsOpenBillModal(false);
  };

  const showInvoiceModal = (items) => {
    setBillList(items);
    setIsOpenBillModal(true);
  };

  return (
    <Container>
      <Head>
        <h1>Member List</h1>
        <div>
          <button
            className="filterBtn"
            type="button"
            onClick={() => handleFilter()}
          >
            {/* <MdAdd size={20} className="mdAdd" /> */}
            {fileterStatus ? "Show all" : "Show active"}
          </button>
          <button
            className="defaultBtn"
            type="button"
            onClick={() => handleAddMember()}
          >
            <MdAdd size={20} className="mdAdd" />
            Add
          </button>
          <Input
            className="search"
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </div>
      </Head>

      <ContentWrapper>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationPerPage={30}
          paginationRowsPerPageOptions={[30, 40, 50]}
          progressPending={isLoading}
          progressComponent={
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#666666"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          }
          customStyles={customStyles}
        />
      </ContentWrapper>
      <CustomModal isOpen={isOpen}>
        <MemberForm closeModal={() => setIsOpen(false)} member={item} />
      </CustomModal>
      <BillListModal
        showModal={isOpenBillModal}
        closeModal={closeBillModal}
        bills={billList}
      />
    </Container>
  );
};

export default Members;
