import React, { useState, useEffect, useInsertionEffect } from "react";
import { MdCheck, MdKeyboardArrowLeft, MdClose } from "react-icons/md";
import Input from "../../components/Form/Input";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import api from "../../services/api";

import { Container, Head, DivForm, Form, Footer, Selec } from "./styles";

const MembershipForm = ({
  closeModal,
  membership,
  defaultTaxStatus,
  defaultTaxRate,
}) => {
  const [taxPrice, setTaxPrice] = useState(0.0);

  const [item, setItem] = useState({
    id: membership ? membership.id : 0,
    title: membership ? membership.title : "",
    duration: membership ? membership.duration : 1,
    durationType: membership ? membership.durationType : 0,
    isRecurring: membership ? membership.isRecurring : 1,
    isActive: membership ? membership.isActive : 1,
    numberOfUsage: membership ? membership.numberOfUsage : 0,
    taxStatus: membership ? membership.taxStatus : defaultTaxStatus,
    taxRateId: membership ? membership.taxRateId : defaultTaxRate.id,
    taxRate: membership ? membership.taxRate : defaultTaxRate.taxRate,
    price: membership ? membership.price : 0.0,
    totalPrice: membership ? membership.totalPrice : 0.0,
  });

  const [taxRates, setTaxRates] = useState([]);

  const [selectedTaxRate, setSelectedTaxRate] = useState({
    id: item.taxRateId,
    name: item.taxRate,
  });

  const [selectedTaxStatus, setSelectedTaxStatus] = useState({
    id: item.taxStatus,
    name: item.taxStatus == "include" ? "Include" : "Exclude",
  });

  useEffect(() => {
    async function loadTaxRates() {
      const taxRes = await api.get("settings/tax");
      setTaxRates(taxRes.data.taxRates);
    }
    loadTaxRates();
  }, []);

  useEffect(() => {
    const subTotal = parseFloat(item.price);
    const tax = parseFloat((subTotal * item.taxRate) / 100);
    if (item.taxStatus == "include") {
      setItem({
        ...item,
        totalPrice: subTotal.toFixed(2),
      });
    } else {
      setItem({
        ...item,
        totalPrice: (subTotal + tax).toFixed(2),
      });
    }
    setTaxPrice(tax.toFixed(2));
  }, [item.price, item.duration, item.taxStatus, item.taxRate]);

  const handleSave = async (e) => {
    e.stopPropagation();
    if (membership) {
      try {
        await api.put(`memberships/${item.id}`, item);
        window.location.href = "/memberships";
        toast.success("Membership edited successfully!");
      } catch (err) {
        const errMsg = err.response
          ? err.response.data.error
          : "Something went wrong!";
        toast.error(errMsg);
      }
    } else {
      try {
        await api.post("memberships", item);
        window.location.href = "/memberships";
        toast.success("Membership added successfully!");
      } catch (err) {
        const errMsg = err.response
          ? err.response.data.error
          : "Something went wrong!";
        toast.error(errMsg);
      }
    }
  };

  const handleGoBack = () => {
    closeModal();
  };

  const durationTypeOptions = [
    {
      value: 0,
      label: "month",
    },
    {
      value: 1,
      label: "day",
    },
  ];

  const taxStatusOptions = [
    {
      value: "include",
      label: "Include",
    },
    {
      value: "exclude",
      label: "Exclude",
    },
  ];
  const activeTypeOptions = [
    {
      value: 0,
      label: "inactive",
    },
    {
      value: 1,
      label: "active",
    },
  ];
  const handleTaxStatusValue = () => {
    if (selectedTaxStatus) {
      return {
        value: selectedTaxStatus.id,
        label: selectedTaxStatus.name,
      };
    }
  };

  const handleTaxStatusChange = (event) => {
    setSelectedTaxStatus({ id: event.value, name: event.label });
    setItem({
      ...item,
      taxStatus: event.value,
    });
  };

  const handleTaxRateValue = () => {
    if (selectedTaxRate) {
      return {
        value: selectedTaxRate.id,
        label: selectedTaxRate.name,
      };
    }
  };

  const handleTaxRateChange = (event) => {
    setSelectedTaxRate({ id: event.value, name: event.label });
    setItem({
      ...item,
      taxRateId: event.value,
      taxRate: event.label,
    });
  };
  return (
    <Container>
      <Form /* schema={} */>
        <Head>
          <h1>{membership ? "Edit Membership" : "Add Membership"}</h1>
          <div className="divBtn">
            <button
              type="button"
              onClick={() => handleGoBack()}
              className="defaultBtn backBtn"
            >
              <MdClose size={20} className="mdAdd" />
            </button>
          </div>
        </Head>
        <DivForm>
          <div>
            <ul>
              <li>
                Title
                <Input
                  name="title"
                  type="text"
                  value={item.title}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      title: e.target.value,
                    });
                  }}
                />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                Duration
                <Input
                  name="duration"
                  type="number"
                  min={1}
                  value={item.duration}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      duration: e.target.value == 0 ? 1 : e.target.value,
                    });
                  }}
                />
              </li>
              <li>
                Duration Type
                <Selec
                  id="durationType"
                  options={durationTypeOptions}
                  defaultValue={durationTypeOptions[item.durationType]}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      durationType: e.value,
                    });
                  }}
                />
              </li>
              <li>
                Price
                <Input
                  name="price"
                  type="number"
                  step="0.01"
                  value={parseFloat(item.price).toFixed(2)}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      price: e.target.value,
                    });
                  }}
                />
              </li>
              <li>
                Active
                <Selec
                  id="isActive"
                  options={activeTypeOptions}
                  defaultValue={activeTypeOptions[item.isActive]}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      isActive: e.value,
                    });
                  }}
                />
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                Tax Status
                <Selec
                  name="taxStatus"
                  options={taxStatusOptions}
                  defaultValue={handleTaxStatusValue()}
                  onChange={handleTaxStatusChange}
                />
              </li>
              <li>
                Tax Rate
                <Selec
                  id="taxRate"
                  options={taxRates.map((item) => ({
                    value: item.id,
                    label: item.taxRate,
                  }))}
                  defaultValue={handleTaxRateValue()}
                  onChange={handleTaxRateChange}
                />
              </li>
              <li>
                Tax Amount
                <Input name="taxPrice" type="text" value={taxPrice} disabled />
              </li>
              <li>
                Total Price
                <Input
                  name="total"
                  type="text"
                  value={item.totalPrice}
                  disabled
                />
              </li>
            </ul>
          </div>
          <div>
            <label className="noRecurringTitle">No Recurring</label>
            <Input
              name="isRecurring"
              type="checkbox"
              checked={item.isRecurring == 0 ? true : false}
              onChange={(e) => {
                setItem({
                  ...item,
                  isRecurring: e.target.checked ? 0 : 1,
                });
              }}
            />

            {item.isRecurring == 0 && (
              <Input
                className="numberOfUsage"
                name="numberOfUsage"
                type="number"
                min={1}
                value={parseInt(item.numberOfUsage)}
                onChange={(e) => {
                  setItem({
                    ...item,
                    numberOfUsage: e.target.value,
                  });
                }}
              />
            )}
          </div>
        </DivForm>
        <Footer>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdKeyboardArrowLeft size={20} className="mdAdd" />
              Back
            </button>
            <button className="defaultBtn" type="button" onClick={handleSave}>
              <MdCheck size={20} className="mdAdd" />
              Save
            </button>
          </div>
        </Footer>
      </Form>
    </Container>
  );
};
MembershipForm.propTypes = {
  closeModal: PropTypes.func,
  members: PropTypes.object,
  defaultTaxStatus: PropTypes.string,
  defaultTaxRate: PropTypes.object,
};
export default MembershipForm;
