import React, { useState } from "react";
import { MdCheck, MdKeyboardArrowLeft, MdClose } from "react-icons/md";
import Input from "../../../components/Form/Input";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import api from "../../../services/api";

import { Container, Head, DivForm, Form, Footer, Selec } from "./styles";

const TaxForm = ({ closeModal, tax }) => {
  const [item, setItem] = useState({
    id: tax ? tax.id : 0,
    taxName: tax ? tax.taxName : "",
    taxRate: tax ? tax.taxRate : 0,
    isDefault: tax ? tax.isDefault : 0,
  });

  const isDefaultOptions = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const handleSave = async () => {
    if (tax) {
      try {
        await api.put(`/settings/tax/${item.id}`, item);
        window.location.href = "/settings/tax";
        toast.success("Tax edited successfully!");
      } catch (err) {
        toast.error("Something went wrong!");
      }
    } else {
      try {
        await api.post("/settings/tax", item);
        window.location.href = "/settings/tax";
        toast.success("Tax added successfully!");
      } catch (err) {
        toast.error("Something went wrong!");
      }
    }
  };
  const handleGoBack = () => {
    closeModal();
  };

  return (
    <Container>
      <Form /* schema={} */ onSubmit={handleSave}>
        <Head>
          <h1>{tax ? "Edit Tax" : "Add Tax"}</h1>
          <div className="divBtn">
            <button
              type="button"
              onClick={() => handleGoBack()}
              className="defaultBtn backBtn"
            >
              <MdClose size={20} className="mdAdd" />
            </button>
          </div>
        </Head>
        <DivForm>
          <strong>Tax Name</strong>
          <Input
            name="taxName"
            type="text"
            value={item.taxName}
            onChange={(e) => {
              setItem({
                ...item,
                taxName: e.target.value,
              });
            }}
          />
          <strong>Tax Rate</strong>
          <Input
            name="taxRate"
            type="number"
            value={item.taxRate}
            onChange={(e) => {
              setItem({
                ...item,
                taxRate: e.target.value,
              });
            }}
          />
          {tax && (
            <>
              <strong>Is Default</strong>
              <Selec
                id="isDefault"
                options={isDefaultOptions}
                defaultValue={isDefaultOptions[item.isDefault]}
                onChange={(e) => {
                  setItem({
                    ...item,
                    isDefault: e.value,
                  });
                }}
              />
            </>
          )}
        </DivForm>
        <Footer>
          <div className="divBtn">
            <button
              className="defaultBtn backBtn"
              type="button"
              onClick={() => handleGoBack()}
            >
              <MdKeyboardArrowLeft size={20} className="mdAdd" />
              BACK
            </button>
            <button className="defaultBtn" type="submit">
              <MdCheck size={20} className="mdAdd" />
              SAVE
            </button>
          </div>
        </Footer>
      </Form>
    </Container>
  );
};
TaxForm.propTypes = {
  closeModal: PropTypes.func,
  tax: PropTypes.object,
};
export default TaxForm;
